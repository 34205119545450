@import "../../styles/functions";
@import "../../styles/variables.scss";
@import "../../styles/mixins";

.box {
  width: 100%;
  position: relative;

  &__inner {
    width: 100%;

    &.error {
      textarea {
        border: pxToRem(1px) solid $colorRed;
        color: $colorRed;

        &::placeholder {
          color: $colorRed;
        }
      }
    }
  }

  .label {
    margin-bottom: pxToRem(5px);

    &.required {
    }

    &::after {
      content: "*";
    }
  }

  &.sm {
    textarea {
      height: pxToRem(30px);
    }
  }

  &.with-label {
    textarea {
      height: pxToRem(28px);
    }
  }

  &.resizable {
    textarea {
      resize: both;
    }
  }

  textarea {
    border: pxToRem(1px) solid $colorLightGray;
    border-radius: pxToRem(5px);
    outline: none;
    background: transparent;
    height: pxToRem(154px);
    transition: 0.3s;

    padding: pxToRem(10px);
    resize: none;
    display: flex;
    align-items: center;

    max-width: pxToRem(579px);
    width: 100%;
    @include typo_16_light;
    color: $colorBlack;

    &::placeholder {
      @include typo_16_light;
      font-style: italic;
      letter-spacing: 0;

      color: $colorBlack;
    }

    &:active,
    &:focus,
    &:hover {
      border-color: $colorBlack;
    }
  }

  &.disabled {
  }

  .error__msg {
    color: $colorRed;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: pxToRem(10px);
    margin-top: pxToRem(0px);
    position: absolute;
    transition: 0.3s;
    width: 100%;

    svg {
      margin-top: pxToRem(3px);
    }

    @include typo_14_light;
  }
}

@media screen and (max-width: 1024px) {
  .box {
    textarea {
      @include typo_16_light;
      max-width: 100%;

      &::placeholder {
        @include typo_16_light;
        letter-spacing: 0;
        color: $colorBlack;
      }
    }
  }
}
