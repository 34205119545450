@import "../../styles/functions";
@import "../../styles/variables.scss";
@import "../../styles/mixins";

.box {
  .label {
    display: flex;
    align-items: center;
    gap: pxToRem(10px);

    cursor: pointer;

    span {
      @include typo_14_light;
    }
  }

  &.secondary {
    .switch {
      border: pxToRem(1px) solid $colorBlack;
      border-radius: $borderRadiusBig !important;

      width: pxToRem(96px);
      height: pxToRem(36px);

      [class*="react-switch-bg"] {
        background: transparent !important;
        height: 100% !important;

        div {
          width: 100% !important;
          height: 100% !important;
          display: flex;
          align-items: center;
          padding: pxToRem(0px) pxToRem(13px);

          @include typo_18_regular;

          &:nth-child(1) {
            justify-content: flex-start;
          }
          &:nth-child(2) {
            justify-content: flex-end;
          }
        }
      }

      [class*="react-switch-handle"] {
        background: $colorBlack !important;
        width: pxToRem(45px) !important;
        height: pxToRem(30px) !important;
        border-radius: $borderRadiusBig !important;
        top: calc(50% - pxToRem(15px)) !important;
        left: pxToRem(1px);
        box-shadow: none !important;

        div {
          color: $colorWhite !important;
          width: 100% !important;
          height: 100% !important;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
          @include typo_18_regular;
        }
      }

      [class*="react-switch-bg"] {
        width: 100% !important;
      }

      &.checked {
        border: pxToRem(1px) solid $colorBlack;
        [class*="react-switch-handle"] {
          transform: translateX(pxToRem(46px)) !important;
          background: $colorBlack !important;
        }
      }
    }
  }

  .switch {
    border: pxToRem(1px) solid $colorLightGray;
    width: pxToRem(28px);
    height: pxToRem(15px);
    border-radius: $borderRadiusBig !important;

    [class*="react-switch-bg"] {
      background: transparent !important;
    }

    [class*="react-switch-handle"] {
      background: $colorLightGray !important;
      width: pxToRem(11px) !important;
      height: pxToRem(11px) !important;
      transform: translateX(pxToRem(1px)) !important;
      top: pxToRem(1px) !important;
    }

    &.checked {
      border: pxToRem(1px) solid $colorGreen;

      [class*="react-switch-handle"] {
        transform: translateX(pxToRem(14px)) !important;
        background: $colorGreen !important;
      }
    }
  }

  &.disabled {
    pointer-events: none;
  }
}

@media (max-width: 1024px) {
  .box {
    &.secondary {
      .switch {
        border: pxToRem(1px) solid $colorBlack;

        width: pxToRem(78px);
        height: pxToRem(29px);

        [class*="react-switch-bg"] {
          div {
            padding: pxToRem(0px) pxToRem(8px);
            @include typo_16_regular;
          }
        }

        [class*="react-switch-handle"] {
          width: pxToRem(37px) !important;
          height: pxToRem(25px) !important;
          top: calc(50% - pxToRem(12.5px)) !important;

          div {
            @include typo_16_regular;
          }
        }

        &.checked {
          [class*="react-switch-handle"] {
            transform: translateX(pxToRem(37px)) !important;
          }
        }
      }
    }
  }
}
