@import "../../styles/functions";
@import "../../styles/variables";
@import "../../styles/mixins";

.box {
  &__inner {
    padding-top: pxToRem(120px);
    padding-bottom: pxToRem(150px);
    max-width: pxToRem(700px);
    width: 100%;
    margin: 0 auto;
  }

  .title {
    @include typo_50_thin;
    margin-bottom: pxToRem(45px);
  }

  .text {
    @include typo_26_light;
  }

  .button {
    margin-top: pxToRem(90px);
    max-width: pxToRem(199px);
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .box {
    &__inner {
      padding-top: pxToRem(60px);
      padding-bottom: pxToRem(90px);
      max-width: pxToRem(700px);
    }

    .title {
      @include typo_28_thin;
      padding: 0 pxToRem(20px);
      margin-bottom: pxToRem(25px);
    }

    .text {
      padding: 0 pxToRem(20px);
      @include typo_24_light;
    }

    .button {
      padding: 0 pxToRem(20px);
      margin-top: pxToRem(60px);
      max-width: pxToRem(500px);
      width: 100%;
    }
  }
}
