@import "../../../styles/functions";
@import "../../../styles/variables.scss";
@import "../../../styles/mixins";

.box {
  width: 100%;
  padding: pxToRem(23px) 0 pxToRem(18px);
  background: var(--product-slider-bg, "transparent");

  .header {
    position: relative;
  }

  .title {
    margin-bottom: pxToRem(45px);
    height: pxToRem(40px);
    position: absolute;
  }

  .slider {
    max-width: pxToRem(1920px);

    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: relative;

    &:hover {
      :global(.swiper-button-disabled) {
        opacity: 0.5;
      }
    }
  }

  :global(.swiper-wrapper) {
    // transform: translate3d(pxToRem(90px), 0px, 0px) !important;
  }

  :global(.swiper) {
    width: 100%;
    height: 100%;
    border-radius: pxToRem(5px);
    overflow: hidden;
    padding: pxToRem(22px) pxToRem(90px);

    :global(.swiper-wrapper) {
      height: 100%;
    }

    :global(.swiper-slide) {
      height: 100%;
      border-radius: pxToRem(5px);
      overflow: unset !important;
      width: auto !important;
      margin-right: 0 !important;

      &:not(:last-child) {
        margin-right: pxToRem(20px) !important;
      }
    }
  }

  :global(.swiper-button-disabled) {
    border: pxToRem(1px) solid rgba(170, 170, 170, 1) !important;
    svg {
      path {
        stroke: rgba(170, 170, 170, 1);
      }
    }

    &:hover {
      background: transparent !important;
      svg {
        path {
          stroke: rgba(170, 170, 170, 1) !important;
        }
      }
    }
  }

  :global(.swiper-button-lock) {
    display: none !important;
  }

  &.no-swipe {
    padding-bottom: 0;
  }

  &.hide-controls {
    .button_prev,
    .button_next {
      display: none;
    }
  }

  .button_prev,
  .button_next {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: pxToRem(40px);
    height: pxToRem(40px);
    border-radius: 50%;
    z-index: 10;
    top: pxToRem(-75px);
    border: pxToRem(1px) solid #333333;
    transition: opacity 0.3s;
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: $colorBlack;
        svg {
          path {
            stroke: $colorWhite;
          }
        }
      }
    }

    svg {
      width: pxToRem(14px);
    }
  }

  .button_prev {
    right: pxToRem(53px);
  }

  .button_next {
    right: 0;
    transform: rotate(-180deg);
  }
}

@media (max-width: 1024px) {
  .box {
    padding: pxToRem(0px) 0 pxToRem(55px);
    position: relative;

    .header {
      position: unset;
    }

    .title {
      position: unset;
      height: 0;
      margin-bottom: 0;
    }

    :global(.swiper) {
      padding: pxToRem(13px) pxToRem(30px);

      :global(.swiper-slide) {
        &:not(:last-child) {
          margin-right: pxToRem(15px) !important;
        }
      }
    }

    &.highlights {
      :global(.swiper) {
        :global(.swiper-slide) {
          &:not(:last-child) {
            margin-right: pxToRem(10px) !important;
          }
        }
      }
    }

    .button_prev,
    .button_next {
      top: unset;
      bottom: 0;
      left: var(--left-position, pxToRem(30px));

      &:active {
        background: $colorBlack;
        svg {
          path {
            stroke: $colorWhite;
          }
        }
      }

      svg {
        width: pxToRem(14px);
      }
    }

    .button_prev {
      right: pxToRem(53px);
    }

    .button_next {
      left: calc(pxToRem(40px) + var(--left-position, pxToRem(30px)) + pxToRem(15px));
      right: 0;
      transform: rotate(-180deg);
    }

    &.nav-right {
      .button_prev {
        left: unset;
        right: calc(pxToRem(40px) + var(--left-position, pxToRem(30px)) + pxToRem(15px));
      }

      .button_next {
        right: var(--left-position, pxToRem(30px));
        left: unset;
      }
    }
  }
}

@media (max-width: 767px) {
  .box {
    :global(.swiper) {
      padding: pxToRem(15px) pxToRem(20px);
    }

    .button_prev,
    .button_next {
      left: var(--left-position, pxToRem(20px));
    }

    .button_next {
      left: calc(pxToRem(40px) + var(--left-position, pxToRem(20px)) + pxToRem(15px));
    }
  }
}
