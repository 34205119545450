@import "../../styles/functions";
@import "../../styles/variables";
@import "../../styles/mixins";

.box {
  display: flex;
  margin: auto;

  &__inner {
    padding: 0px 0 pxToRem(90px);

    @media (max-width: 768px) {
      padding: 0px 0 pxToRem(60px);
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: pxToRem(10px);

    > *:first-child {
      max-width: pxToRem(340px);
      width: 100%;
    }

    .breadcrumbs {
      // width: 22%;
      max-width: unset;
    }

    .toolbar {
      max-width: pxToRem(1156px);
      width: 75%;

      display: flex;
      align-items: center;
      justify-content: space-between;

      &__col {
        display: flex;
        align-items: center;
        gap: pxToRem(15px);
      }

      &__sort {
        width: pxToRem(230px);
        height: pxToRem(40px);
        position: relative;

        > * {
          position: absolute;
          z-index: 100;
        }
      }
    }
  }

  &__content {
    display: flex;
    gap: pxToRem(90px);

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      gap: pxToRem(53px);
    }
  }

  &__profile {
    display: flex;
    width: 100%;
    max-width: pxToRem(390px);
    height: max-content;

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  &__information {
    width: 100%;
  }
}
