@import "../../../../styles/functions";
@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.accordion {
  max-width: 100%;
}

.title {
  @include typo_18_light;
}

.address {
  display: flex;
  flex-direction: column;
}

.table {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;
  padding: pxToRem(10px) pxToRem(20px) pxToRem(40px);
  border: pxToRem(1px) solid $colorLightGray;
  gap: pxToRem(2px);
  border-radius: pxToRem(5px);

  .row {
    display: flex;
    width: 100%;
    padding: 0;

    &:nth-child(1),
    &:nth-child(2) {
      width: calc((100% - pxToRem(20px)) / 2);
    }

    &:nth-child(2) {
      .col {
        &__title {
          margin-bottom: pxToRem(8px);
        }
      }
    }

    &:nth-child(3) {
      .col {
        border-bottom: pxToRem(1px) solid $colorLightGray;
        padding-bottom: pxToRem(20px);

        &__title {
          margin-bottom: pxToRem(8px);
        }
      }
    }

    &:nth-child(4) {
      .col {
        padding-top: pxToRem(20px);
      }
    }

    &:last-child {
      .col {
        padding-bottom: pxToRem(5px);
        border-bottom: 0;
      }
    }
  }

  .col {
    padding: pxToRem(10px) pxToRem(0px);
    max-width: calc(100% + pxToRem(40px));
    padding-right: pxToRem(40px);

    width: 100%;

    &.center {
      display: flex;
      align-items: center;
      position: relative;
    }

    &.required {
      .col {
        &__title {
          &::after {
            content: "*";
          }
        }
      }
    }

    &:last-child {
      max-width: calc(100%);
      padding-right: 0;
    }

    &__title {
      @include typo_12_medium;
      margin-bottom: pxToRem(2px);
      letter-spacing: 0.02em;
    }

    &__content {
      width: 100%;
      @include typo_16_light;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .big-text {
      @include typo_26_light;
      line-height: pxToRem(30px);
    }

    button {
      width: pxToRem(190px);
      padding-left: 0;
      padding-right: pxToRem(10px);

      @include typo_12_medium;
    }

    .download {
      margin: auto auto;
    }

    .other-btn {
      display: flex;
      align-items: center;
      gap: pxToRem(6px);
      width: max-content;

      span {
        @include typo_12_regular;
        border-bottom: pxToRem(1px) solid $colorGray;
        opacity: 1;
        transition: 0.3s;
        color: $colorBlack;

        &:hover {
          opacity: 0.5;
        }
      }

      svg {
        cursor: pointer;
      }
    }
  }

  .select {
    position: relative;
    width: 100%;
    height: 100%;
    height: pxToRem(43px);
    > * {
      position: absolute;
      z-index: 1;
    }
  }
}

.form__alert {
  color: $colorRed;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: pxToRem(10px);
  margin-top: pxToRem(0px);
  position: absolute;
  opacity: 0;
  transition: 0.3s;

  @include typo_14_light;

  &.visible {
    opacity: 1;
  }
}

@media screen and (max-width: 767px) {
  .table {
    .row {
      &:nth-child(1),
      &:nth-child(2) {
        width: 100%;
      }
    }
  }
}
