@import "../../../styles/functions";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.box {
  position: fixed;
  top: pxToRem(81px);
  width: 100vw;
  transition: 0.3s;
  height: var(--max-height, 100vh);
  z-index: 999;
  background: $colorWhite;

  &__inner {
    height: 100%;
  }

  &.open {
    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }
  }

  &.left {
    left: -200vw;
  }

  &.right {
    right: -200vw;
  }

  &__inner {
  }
}

@media (max-width: 767px) {
  .box {
    top: pxToRem(67px);
  }
}
