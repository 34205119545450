@import "../../styles/functions";
@import "../../styles/variables.scss";
@import "../../styles/mixins";

.box {
  width: 100%;

  &__inner {
    width: 100%;

    &.error {
      input {
        // border-bottom: pxToRem(1px) solid $colorRed;
        color: $colorRed !important;

        &::placeholder {
          color: $colorRed !important;
        }
      }

      .label {
        &.required {
          color: $colorRed;
        }
      }
    }
  }

  .label {
    margin-bottom: pxToRem(5px);
    display: block;

    &.required {
    }

    &::after {
      content: "*";
    }
  }

  &.sm {
    input {
      height: pxToRem(30px);
    }
  }

  &.with-label {
    input {
      height: pxToRem(28px);
    }
  }

  input {
    border: 0;
    // border-bottom: pxToRem(1px) solid $colorBlack;
    outline: none;
    background: transparent;
    height: pxToRem(40px);

    display: flex;
    align-items: center;

    max-width: pxToRem(579px);
    width: 100%;
    @include typo_16_light;
    color: $colorBlack !important;

    &:active,
    &:focus,
    &:hover {
      border-color: $colorBlack;
    }
  }

  input::placeholder {
    @include typo_18_light;
    letter-spacing: 0;
    color: $colorBlack !important;
    opacity: 1 !important;
  }

  &.disabled {
  }
}
