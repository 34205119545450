@import "../../styles/functions";
@import "../../styles/variables.scss";
@import "../../styles/mixins";

.box {
  position: relative;

  &.focused {
  }

  &.active {
    .title {
      top: 0;
      @include typo_12_regular;
    }
  }

  &.error:not(.focused) {
    input {
      border-bottom: pxToRem(1px) solid $colorRed;
      color: $colorRed;
    }

    .title {
      color: $colorRed;
    }
  }
}

.field {
  flex-direction: column;
  column-gap: pxToRem(5px);

  &:hover .description:not(div),
  &.active .description {
    border-color: $colorBlack;
  }

  input {
    border: none;
    position: relative;
    z-index: 1;
    background: transparent;
    transition: 0.3s;
    border-radius: 0;

    &:hover ~ svg {
      opacity: 1;
    }

    &:focus {
      outline: none;
    }
  }

  svg {
    position: absolute;
    right: 0;
    opacity: 0;
    bottom: 50%;
    transform: translate(0px, 50%);
    transition: opacity 0.3s;
  }

  span ~ input {
    padding-top: pxToRem(28px) !important;
  }

  .title {
    display: block;
    text-align: left;

    @include typo_16_regular;
    line-height: pxToRem(18px);
    letter-spacing: 0.02em;
    color: $colorGray-3;
    position: absolute;
    z-index: 1;
    transition: 0.3s;

    top: calc(50% - pxToRem(8px));
  }

  .description {
    padding-top: pxToRem(28px);
    font-family: "Roboto Regular";
    font-size: pxToRem(16px);
    font-weight: 400;
    line-height: pxToRem(21px);
    letter-spacing: 0.02em;
    text-align: left;
    color: $colorBlack;
    padding-bottom: pxToRem(20px);
    border-bottom: 1px solid $colorLightGray;
    width: 100%;
    padding-right: pxToRem(20px);
  }
}

@media screen and (max-width: 767px) {
  .box {
    .title {
    }

    span ~ .description {
      padding-top: pxToRem(28px) !important;
    }

    .description {
      padding-top: pxToRem(5px);
    }
  }
}
