@import "../../styles/functions";
@import "../../styles/variables.scss";

.box {
  position: relative;
  max-width: pxToRem(579px);
  width: 100%;
  overflow: hidden;
  height: pxToRem(45px);

  &.sm {
    height: pxToRem(30px);
  }

  .button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-width: pxToRem(45px);
    min-height: pxToRem(45px);
    width: pxToRem(45px);
    height: pxToRem(45px);
    background: transparent;
    border-radius: 50%;
    transition: 0.3s;

    position: absolute;
    right: 0;
    top: 0;

    &:hover {
      cursor: pointer;
      background: #fdfbf9;
    }
  }

  .input {
    width: calc(100% - pxToRem(50px));
    position: relative;

    .search__panel {
    }
  }

  &:not(.expanded).visible {
    .input {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &.expanded {
    .input {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &.left {
    .input {
      transform: translateX(pxToRem(50px));
      opacity: 1;
    }

    .button {
      left: 0;
      right: unset;
    }
  }

  .input {
    transition:
      transform 0.3s,
      opacity 0.3s;
    transform: translateX(pxToRem(579px));
    opacity: 0;
  }
}

@media (max-width: 1024px) {
  .box {
    &.left {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: flex-end;

      .input {
        transform: translateX(pxToRem(0px));
        opacity: 1;
        margin-left: pxToRem(20px);
      }

      .button {
        position: relative;
        left: unset;
        top: unset;
        right: unset;
        bottom: unset;
        min-width: pxToRem(22px);
        min-height: pxToRem(22px);
        width: pxToRem(22px);
        height: pxToRem(22px);

        svg {
          width: 100%;
          height: 100%;
        }
      }

      &.sm {
        .button {
          transform: translateY(pxToRem(5px));
        }
      }
    }
  }
}
