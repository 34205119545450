@import "../../styles/functions";
@import "../../styles/variables";
@import "../../styles/mixins";

.box {
  &__inner {
    padding-bottom: pxToRem(90px);

    &.success {
      padding-top: pxToRem(120px);
      padding-bottom: pxToRem(150px);
      max-width: pxToRem(900px);
      width: 100%;
      margin: 0 auto;
    }
  }

  .info {
    margin-top: pxToRem(5px);
    margin-bottom: pxToRem(35px);
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: pxToRem(20px);
    margin-top: pxToRem(38px);

    > * {
      max-width: pxToRem(250px);
      width: 100%;
      padding-left: pxToRem(10px);
      padding-right: pxToRem(10px);
    }
  }
}

@media (max-width: 1024px) {
  .box {
    &__inner {
      padding-bottom: pxToRem(60px);

      &.success {
        padding-top: pxToRem(60px);
        padding-bottom: pxToRem(90px);
      }
    }

    .buttons {
      gap: pxToRem(15px);
      margin-top: pxToRem(25px);

      > * {
        max-width: 100%;
        width: 100%;
        padding-left: pxToRem(10px);
        padding-right: pxToRem(10px);
      }
    }
  }
}

@media (max-width: 767px) {
  .box {
    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: pxToRem(15px);
      margin-top: pxToRem(25px);

      > * {
        max-width: 100%;
        width: 100%;
        padding-left: pxToRem(10px);
        padding-right: pxToRem(10px);
      }
    }
  }
}
