@import "../../styles/functions";
@import "../../styles/variables";
@import "../../styles/mixins";

.box {
  &__inner {
    padding-bottom: pxToRem(90px);
  }

  .info {
    margin-top: pxToRem(5px);
    margin-bottom: pxToRem(35px);
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: pxToRem(20px);
    margin-top: pxToRem(38px);

    > * {
      max-width: pxToRem(250px);
      width: 100%;
      padding-left: pxToRem(10px);
      padding-right: pxToRem(10px);
    }
  }
}

@media (max-width: 1024px) {
  .box {
    .buttons {
      > * {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  .box {
    .buttons {
      flex-direction: column-reverse;
      gap: pxToRem(15px);

      > * {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
