@import "../../../styles/functions";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.box {
  width: 100%;
  display: flex;

  padding: pxToRem(10px) 0;
  background: $colorMilky;

  &__inner {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
  }

  .panel {
    height: 0;
    padding: 0;
    border-color: transparent;
    overflow: hidden;
    transition: 0.5s;

    &__inner {
      padding: pxToRem(40px) 0 pxToRem(45px);
      display: flex;
      gap: pxToRem(20px);
    }
  }

  &.open {
    .panel {
      height: var(--max-height, auto);
    }

    .expand__btn {
      svg {
        transform: rotate(90deg);
      }
    }
  }

  .expand__btn {
    display: flex;
    align-items: center;
    gap: pxToRem(25px);
    @include typo_16_light;
    transition: border-bottom 0.3s;

    margin-left: auto;

    svg {
      transition: 0.3s;
      transform: rotate(-90deg);
      width: pxToRem(15px);
    }

    span {
      border-bottom: pxToRem(1px) solid transparent;
    }

    span {
      @include hover_underline;
    }
  }

  .card {
    width: calc((100% - pxToRem(40px)) / 3);
  }
}

@media (max-width: 1024px) {
  .box {
    &__inner {
      max-width: calc(100% - pxToRem(60px));
    }

    .panel {
      &__inner {
        padding: 0 0 pxToRem(24px);
        gap: pxToRem(15px);
      }
    }

    .card {
      width: 100%;
    }

    .expand__btn {
      margin-right: pxToRem(10px);
    }
  }
}

@media (max-width: 767px) {
  .box {
    padding: pxToRem(15px) 0;

    &__inner {
      max-width: calc(100% - pxToRem(20px));
    }

    .panel {
      &__inner {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
