@import "../../styles/functions";
@import "../../styles/variables";
@import "../../styles/mixins";

.box {
  display: flex;
  margin: auto;
  overflow: hidden;

  &__inner {
    padding: 0px 0 pxToRem(90px);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: pxToRem(10px);

    > *:first-child {
      max-width: pxToRem(340px);
      width: 100%;
    }

    .breadcrumbs {
      width: 22%;
      max-width: unset;
    }

    .toolbar {
      max-width: pxToRem(1156px);
      width: 75%;

      display: flex;
      align-items: center;
      justify-content: space-between;

      &__col {
        display: flex;
        align-items: center;
        gap: pxToRem(15px);
      }

      &__sort {
        width: pxToRem(230px);
        height: pxToRem(40px);
        position: relative;

        > * {
          position: absolute;
          z-index: 100;
        }
      }
    }
  }

  .view-btn,
  .filter-btn {
    cursor: pointer;
    width: pxToRem(40px);
    height: pxToRem(40px);
    border-radius: 50%;
    border: pxToRem(1px) solid $colorLightGray;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;

    svg rect {
      transition: 0.3s;
    }

    &:hover {
      border: pxToRem(1px) solid $colorBlack;
      background: $colorBlack;

      svg {
        rect {
          fill: $colorWhite;
          stroke: $colorWhite;
        }
        path {
          stroke: $colorWhite;
        }
      }
    }
  }

  .layout {
    display: flex;
    justify-content: space-between;
    gap: pxToRem(10px);
    // overflow: hidden;
    position: relative;
    padding-top: pxToRem(14px);

    &__title {
      @include typo_12_regular;
      border-bottom: pxToRem(1px) solid transparent;

      &:hover {
        border-bottom: pxToRem(1px) solid $colorBlack;
      }
    }

    &.sidebar-hidden {
      gap: 0;

      .layout {
        &__sidebar {
          opacity: 0;
          width: 0;
        }

        &__content {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    &__sidebar {
      // max-width: pxToRem(340px);
      // width: 100%;
      width: 22%;
      opacity: 1;
      transform: translateX(0px);
      transition: width 0.5s;
    }

    &__content {
      max-width: pxToRem(1156px);
      // width: 100%;
      width: 75%;
      transition: 0.5s;
      padding-bottom: pxToRem(10px);
    }
  }

  .empty {
    padding: pxToRem(140px) 0 0;
    width: 100%;
    text-align: center;
    @include typo_18_light;
  }
}

@media (max-width: 1024px) {
  .box {
    &__inner {
      padding-top: pxToRem(40px);
      padding-bottom: pxToRem(60px);
    }

    &__header {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      gap: pxToRem(0px);

      > *:first-child {
        max-width: 100%;
        width: 100%;
      }

      .breadcrumbs {
        width: 100%;
        max-width: unset;
      }

      .toolbar {
        padding: pxToRem(10px) 0 0;
        max-width: 100%;
        width: 100%;
        justify-content: flex-end;

        &__col {
          gap: pxToRem(15px);
        }

        &__sort {
        }
      }
    }

    .layout {
      padding-top: 0;

      &__title {
        display: none;
      }

      &__content {
        width: 100%;
        max-width: 100%;
      }
    }

    .empty {
      padding: pxToRem(60px) 0 0;
    }
  }
}

@media (max-width: 500px) {
  .box {
    &__header {
      .toolbar {
        &__col {
          width: 100%;
        }

        &__sort {
          width: calc(100% - pxToRem(45px));
        }
      }
    }
  }
}
