@import "../../styles/functions";
@import "../../styles/variables.scss";
@import "../../styles/mixins";

.box {
  width: 100%;
  background: $colorWhite;
  border-radius: pxToRem(5px);
  padding: pxToRem(10px);
  display: flex;
  flex-direction: column;
  gap: pxToRem(10px);
  min-height: pxToRem(109px);

  margin-top: pxToRem(5px);

  position: relative;
  border: pxToRem(1px) solid $colorLightGray;

  position: fixed;
  z-index: 1000;

  max-height: pxToRem(490px);
  overflow-y: auto;
  overflow-x: hidden;

  > * {
    width: 100%;
  }

  &__item {
    @include typo_14_light;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: pxToRem(1px) solid transparent;

    max-width: 100%;
    width: fit-content;

    cursor: pointer;

    @include hover_underline;

    span {
      &:nth-child(1) {
        width: 100%;
        @include typo_12_medium;
      }

      &:nth-child(2) {
        width: 100%;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .msg {
    @include typo_16_light;
    width: 100%;
    height: 100%;
    margin: auto;
    text-align: center;
  }

  .spinner {
    position: relative;
  }

  .tooltip {
    max-width: 95%;
  }
}

@media (max-width: 1024px) {
  .box {
    max-height: pxToRem(325px);
  }
}
