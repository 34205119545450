@import "../../styles/functions";
@import "../../styles/variables.scss";

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: pxToRem(60px);
  height: pxToRem(60px);
  background: $colorDarkBlue;
  border: pxToRem(1px) solid $colorBlack;
  transition: transform 0.3s;
  cursor: pointer;

  &.fixed {
    position: fixed;
    right: pxToRem(40px);
    bottom: pxToRem(100px);
    z-index: 10;
  }

  opacity: 0;
  transition: 0.6s;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  &:hover {
    background: $colorLightGreen;

    svg {
      path {
        stroke: $colorBlack;
      }
    }
  }
}

@media (max-width: 1024px) {
  .box {
    width: pxToRem(40px);
    height: pxToRem(40px);

    .fixed {
      display: none;
    }
  }
}
