@import "../../styles/functions";
@import "../../styles/variables.scss";
@import "../../styles/mixins";

.box {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: $colorWhite;
  border-radius: pxToRem(5px);
  border: pxToRem(1px) solid $colorLightGray;

  padding: pxToRem(35px);

  .title {
    @include typo_21_regular;
    margin-bottom: pxToRem(20px);
  }

  .area {
    display: flex;
    align-items: center;
    width: 100%;
    height: pxToRem(125px);
    border: pxToRem(1px) solid $colorLightGray;
    border-radius: pxToRem(5px);
    padding: pxToRem(25px);
    cursor: pointer;
    position: relative;
    margin-bottom: pxToRem(20px);

    transition: border-style 0.3s;

    &:hover,
    &.drag-over {
      border: pxToRem(1px) dashed $colorBlack;
      //   background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23333' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    }

    &.error {
      border: pxToRem(1px) dashed $colorRed;
      //   background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23EB5757FF' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    }

    &.success {
      border: pxToRem(1px) dashed $colorGreen;
    }

    .hidden {
      opacity: 0;
      transform: translate(-100vh);
      position: absolute;
    }

    &.file {
      border: pxToRem(1px) solid $colorDarkBlue;

      &.success {
        border: pxToRem(1px) solid $colorGreen;
      }

      &.error {
        border: pxToRem(1px) solid $colorRed;
      }
    }
  }

  .empty {
    display: flex;
    align-items: center;
    gap: pxToRem(10px);

    margin: 0 auto;

    @include typo_16_regular;
  }

  .info {
    // margin-top: pxToRem(20px);
    margin-top: auto;
    // margin-bottom: pxToRem(10px);
    display: flex;
    justify-content: flex-start;
    gap: pxToRem(20px);
    align-items: center;
  }

  .tooltip {
    display: flex;
    align-items: center;
    gap: pxToRem(10px);

    cursor: pointer;
    transition: 0.3s;

    @include typo_12_medium;
    color: $colorGray;

    &:hover {
      color: $colorBlack;

      svg {
        path {
          stroke: $colorWhite;
        }
        circle {
          fill: $colorBlack;
          stroke: $colorBlack;
        }
      }
    }

    svg * {
      transition: 0.3s;
    }
  }

  .tooltip__content {
    background: $colorWhite;
    color: $colorBlack;
    border: pxToRem(1px) solid $colorBlack;
    padding: pxToRem(10px);
    max-width: pxToRem(254px);
    width: 100%;

    &.sm {
      max-width: pxToRem(260px);
    }

    --rt-opacity: 1;

    @include typo_12_regular;

    [class*="react-tooltip-arrow"] {
      background: $colorWhite;
      border-bottom: pxToRem(1px) solid $colorBlack;
      border-right: pxToRem(1px) solid $colorBlack;
      width: pxToRem(7px);
      height: pxToRem(7px);
      left: pxToRem(11px) !important;
    }
  }

  .error {
    display: flex;
    align-items: center;
    margin-left: auto;
    gap: pxToRem(5px);

    @include typo_12_light;
  }

  .template {
    display: flex;
    align-items: center;
    gap: pxToRem(10px);
    @include typo_12_medium;
    color: $colorGray;

    &:hover {
      color: $colorBlack;

      svg {
        path {
          stroke: $colorWhite;
          fill: $colorBlack;
        }
      }
    }

    svg * {
      transition: 0.3s;
    }
  }

  .drop-text {
    span {
      text-decoration: underline;
    }
  }
}

.tooltip {
}

.success-msg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: pxToRem(10px);
  @include typo_16_regular;
  max-width: pxToRem(312px);
  text-align: center;
  line-height: pxToRem(18px);
  width: 100%;
  margin: 0 auto;

  span {
    color: $colorGreen;
    font-family: "Roboto Bold";
    font-size: pxToRem(16px);
    font-weight: 700;
    line-height: pxToRem(18px);
    letter-spacing: 0.02em;
    text-align: center;
  }
}
