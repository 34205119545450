@import "../../styles/functions";
@import "../../styles/variables.scss";
@import "../../styles/mixins";

.box {
  position: relative;

  &.focused,
  &:hover {
    .field {
      border-color: $colorBlack;
    }

    .title {
      top: 0;
      @include typo_12_regular;
    }

    svg {
      opacity: 1;
    }
  }

  &.error:not(.focused) {
    .field {
      border-bottom: pxToRem(1px) solid $colorRed;

      .input {
        color: $colorRed;
      }
    }

    .title {
      color: $colorRed;
    }
  }
}

.field {
  flex-direction: column;
  column-gap: pxToRem(5px);
  padding-right: pxToRem(20px);

  padding-top: pxToRem(27px);
  padding-bottom: pxToRem(7px);

  border-bottom: 1px solid $colorLightGray;

  .dropdown,
  .input {
    border: none;
    background: transparent;
  }

  .dropdown {
    > div {
      width: pxToRem(38px);
      padding: 0 0 0 pxToRem(8px);
      > div {
        margin-top: pxToRem(-5px) !important;
        width: pxToRem(16px);
        height: pxToRem(11px);
      }
    }
  }

  .input {
    transition: 0.3s;
    border-radius: 0;

    @include typo_16_regular;
    line-height: pxToRem(21px);
    letter-spacing: 0.02em;
    text-align: left;
    color: $colorBlack;

    &:hover ~ svg {
      opacity: 1;
    }

    &:focus {
      outline: none;
    }
  }

  svg {
    position: absolute;
    right: 0;
    opacity: 0;
    bottom: 50%;
    transform: translate(0px, 50%);
    transition: opacity 0.3s;
  }

  .title {
    display: block;
    text-align: left;
    position: absolute;
    top: 0;
    z-index: 1;
    transition: 0.3s;

    @include typo_12_regular;
    letter-spacing: 0.02em;
    color: $colorGray-3;
  }

  .description {
    @include typo_16_regular;
    line-height: pxToRem(21px);
    letter-spacing: 0.02em;
    text-align: left;
    color: $colorBlack;
    border-bottom: 1px solid $colorLightGray;
    width: 100%;
  }

  [class*="react-tel-input"] {
    font-size: pxToRem(15px);
    input {
      height: 100%;
      padding-left: pxToRem(48px);
      width: 100%;
    }
  }

  [class*="country-list"] {
    margin: pxToRem(10px) 0 pxToRem(10px) pxToRem(-1px);
    width: pxToRem(300px);
    max-height: pxToRem(200px);
    border-radius: 0 0 pxToRem(3px) pxToRem(3px);
    box-shadow: pxToRem(1px) pxToRem(2px) pxToRem(10px) rgba(0, 0, 0, 0.35);
  }
}

@media screen and (max-width: 767px) {
  .box {
    .title {
    }

    .description {
      padding-top: pxToRem(5px);
    }

    [class*="country-list"] {
      max-height: pxToRem(150px);
    }
  }
}
