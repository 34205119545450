@import "../../../../styles/functions";
@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.accordion {
  max-width: 100%;
}

.title {
  @include typo_18_light;
}

.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  padding: pxToRem(20px);

  border: 0;
  border-radius: 0;

  .row {
    display: flex;
    width: 100%;
    padding: 0;

    &:nth-child(3),
    &:nth-child(7) {
      .col {
        border-bottom: pxToRem(1px) solid $colorLightGray;
        padding-bottom: pxToRem(20px);
      }
    }

    &:nth-child(4),
    &:nth-child(8) {
      .col {
        padding-top: pxToRem(20px);
      }
    }

    &:last-child {
      .col {
        padding-bottom: pxToRem(5px);
      }
    }
  }

  .col {
    padding: pxToRem(10px) pxToRem(0px);
    max-width: calc(100% + pxToRem(40px));
    padding-right: pxToRem(40px);

    width: 100%;

    &.center {
      display: flex;
      align-items: center;
      position: relative;
      // a {
      //   position: absolute;
      //   right: pxToRem(-8px);
      //   top: calc(50% - pxToRem(10px));
      // }
    }

    &:last-child {
      max-width: calc(100%);
      padding-right: 0;
    }

    &__title {
      @include typo_12_medium;
    }

    &__content {
      width: 100%;
      @include typo_16_light;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .big-text {
      @include typo_26_light;
      line-height: pxToRem(30px);
    }

    button:not(.download-file) {
      width: pxToRem(190px);
      padding-left: 0;
      padding-right: pxToRem(10px);

      @include typo_12_medium;
    }

    .download {
      margin: auto auto;
    }

    .address {
      display: flex;
      flex-direction: column;
    }
  }
}
