@import "../../../styles/functions";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.box {
  display: flex;
  flex-direction: column;
  margin: auto;

  .year,
  .month,
  .sort {
    margin-bottom: pxToRem(15px);
    position: relative;
    height: pxToRem(60px);
    z-index: 2;

    > * {
      position: absolute;
    }
  }

  .year {
    z-index: 3;

    > * {
    }
  }

  .sort {
    display: none;
    z-index: 1;

    > * {
    }
  }

  .accordion-regular {
    * {
      font-family: "Roboto Regular";
      font-weight: 400;
    }
  }
}

@media (max-width: 1024px) {
  .box {
    .year,
    .month,
    .sort {
      margin-bottom: pxToRem(10px);
      position: relative;
      height: pxToRem(40px);

      > * {
        max-width: 100%;
      }
    }

    .sort {
      display: flex;
      margin-bottom: 0;
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: pxToRem(15px);
  }
}

@media (min-width: 500px) and (max-width: 768px) {
  .box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: pxToRem(15px);
  }
}
