@import "../../../styles/functions";
@import "../../../styles/variables.scss";
@import "../../../styles/mixins";

.box {
  background: $colorMilky;
  border-radius: pxToRem(5px);
  overflow: hidden;
  max-width: pxToRem(372px);
  width: 100%;
  height: 100%;
  transition: box-shadow 0.3s;
  position: relative;

  &:hover {
    box-shadow: 0px 0px pxToRem(25px) 0px #00000033;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .bg {
    width: 100%;
    height: 100%;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #00000033;
    }
  }

  .info {
    position: absolute;
    padding: 0 pxToRem(34px);
    bottom: pxToRem(40px);
    left: 0;

    color: $colorWhite;

    .title {
      @include typo_50_thin;
      margin-bottom: pxToRem(16px);
      max-width: 90%;
    }

    .desc {
      @include typo_14_light;
    }
  }

  .link {
    display: inline-block;
    margin-top: pxToRem(6px);
    @include typo_16_regular;
    transition: 0.3s;
    border-bottom: pxToRem(1px) solid transparent;
    color: $colorWhite;

    @include hover_underline(-3px, $colorWhite);
  }
}

@media (max-width: 1024px) {
  .box {
    max-width: 100%;

    .info {
      padding: 0 pxToRem(10px);

      .title {
        @include typo_28_thin;
      }
    }
  }
}
