@import "../../../styles/functions";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.box {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 500;
  background: $colorWhite;
  height: pxToRem(40px);
  border-bottom: pxToRem(1px) solid $colorLightGray;

  display: grid;
  grid-template-columns: 1fr 1fr;

  &__col {
    &,
    * {
      @include typo_16_regular;
      line-height: pxToRem(21px);
    }

    &:nth-child(1) {
      border-right: pxToRem(1px) solid $colorLightGray;
    }

    &:nth-child(2) {
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: pxToRem(10px);
    height: 100%;
    width: 100%;
    padding: 0px pxToRem(20px);

    span {
      @include text_overflow_elipses(1);
      white-space: normal;
      text-align: left;
    }

    &__icon {
      cursor: pointer;
      height: pxToRem(40px);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: pxToRem(15px);
        height: pxToRem(15px);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .box {
    top: pxToRem(81px);

    &__col {
      &:nth-child(1) {
        .button {
          padding: 0px pxToRem(30px);
          justify-content: center;
        }
      }
      &:nth-child(2) {
      }
    }

    .button {
      padding: 0px pxToRem(30px);
      justify-content: center;
    }
  }
}

@media screen and (max-width: 767px) {
  .box {
    top: pxToRem(65px);

    &__col {
      &:nth-child(1) {
        .button {
          padding: 0px pxToRem(20px);
          justify-content: center;
        }
      }
      &:nth-child(2) {
      }
    }

    .button {
      padding: 0px pxToRem(20px);
      justify-content: center;
    }
  }
}
