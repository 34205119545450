@import "../../styles/functions";
@import "../../styles/variables";
@import "../../styles/mixins";

.box {
  .title {
    @include typo_50_thin;
    margin-bottom: pxToRem(45px);
  }

  .text {
    @include typo_26_light;

    span {
      // @include typo_26_regular;
    }
  }

  .body {
    padding-top: pxToRem(120px);
    padding-bottom: pxToRem(150px);
    max-width: pxToRem(900px);
    width: 100%;
    margin: 0 auto;

    &.padding-small {
      padding-top: pxToRem(30px);
    }
  }

  .button {
    margin-top: pxToRem(90px);
    max-width: pxToRem(250px);
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .box {
    .body {
      padding-bottom: pxToRem(90px);
      max-width: 100%;
    }

    .title {
      @include typo_28_thin;
      padding: 0 pxToRem(20px);
      margin-bottom: pxToRem(40px);
    }

    .text {
      padding: 0 pxToRem(20px);
      @include typo_16_light;

      span {
        @include typo_16_light;
      }
    }

    .button {
      padding: 0 pxToRem(20px);
      margin-top: pxToRem(60px);
      max-width: pxToRem(500px);
      width: 100%;
    }
  }
}
