@import "../../../styles/functions";
@import "../../../styles/variables.scss";
@import "../../../styles/mixins";

.box {
  border: pxToRem(1px) solid $colorLightGray;
  max-width: pxToRem(340px);
  width: 100%;

  &.single {
    border-radius: pxToRem(5px) !important;
  }

  &:first-child {
    border-radius: pxToRem(5px) pxToRem(5px) 0 0;
  }

  &:not(:last-child) {
    border-bottom: none;
  }

  &:last-child {
    border-radius: 0 0 pxToRem(5px) pxToRem(5px);
  }

  &.fill {
    background: $colorMilky;
    border: pxToRem(1px) solid $colorMilky;
  }

  &.uppercase {
    &,
    * {
      text-transform: uppercase;
    }
  }

  &.border-bottom {
    border: 0;
    border-bottom: pxToRem(1px) solid $colorLightGray;
    border-radius: 0 !important;
    max-width: 100%;

    .button {
      padding: pxToRem(14px) 0;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  &.sm {
    .button {
      padding: pxToRem(5px) pxToRem(20px);
      gap: pxToRem(9px);

      &__label {
        @include typo_16_regular;
      }
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: pxToRem(40px);
    cursor: pointer;
    padding: pxToRem(17px) pxToRem(20px) pxToRem(15px);

    outline: none;

    &__label {
      border-bottom: pxToRem(1px) solid transparent;

      &.hover {
        &:hover {
          border-bottom: pxToRem(1px) solid $colorBlack;
        }
      }
    }

    @include typo_18_medium;

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: pxToRem(20px);
      height: pxToRem(20px);
      transition: 0.3s;
      border-radius: 50%;
      border: pxToRem(1px) solid transparent;

      &.active {
        transform: rotate(180deg);
        border: pxToRem(1px) solid $colorBlack;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background: $colorBlack;
          svg {
            path {
              stroke: $colorWhite;
            }
          }
        }
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  .panel {
    height: 0;
    padding: 0;
    border-color: transparent;
    overflow: hidden;
    transition:
      padding 0.5s,
      margin 0.5s,
      height 0.5s,
      border-color 0.5s;
  }

  &.open {
    .panel {
      height: var(--max-height, auto);
    }
  }

  &.collapse-on-blur {
    .collapse-bg {
      position: fixed;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: transparent;
      z-index: -1;
    }
  }
}

@media screen and (max-width: 767px) {
  .box {
    .button {
      &__icon {
        width: pxToRem(21px);
        height: pxToRem(20px);

        svg {
          width: 9px;
          height: 12px;
        }

        &:active {
          background: $colorBlack;
          svg {
            path {
              stroke: $colorWhite;
            }
          }
        }
      }
    }
  }
}
