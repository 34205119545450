@import "../../../styles/functions";

@import "../../../styles/variables";
@import "../../../styles/mixins";

.top {
  display: flex;
  justify-content: space-between;
  gap: pxToRem(90px);
  padding-bottom: pxToRem(15px);
  padding-top: pxToRem(5px);

  &__preview {
    width: 100%;
    max-width: pxToRem(615px);
    height: pxToRem(651px);
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: pxToRem(23px);
    padding-bottom: pxToRem(65px);

    width: 100%;
    max-width: pxToRem(843px);

    &-row {
      max-width: pxToRem(725px);
    }

    .additional {
      border-top: pxToRem(1px) solid $colorLightGray;
      border-bottom: pxToRem(1px) solid $colorLightGray;
      padding: pxToRem(25px) 0;
      margin-top: pxToRem(10px);

      &__list {
        display: flex;
        gap: pxToRem(30px);
        color: $colorBlack;

        &-col {
          display: flex;
          flex-direction: column;
          gap: pxToRem(10px);

          max-width: pxToRem(250px);
          width: 100%;

          &:nth-child(2) {
            .additional__list-row {
              span:nth-child(1) {
                width: pxToRem(103px);
              }
            }
          }
        }

        &-row {
          display: flex;
          align-items: center;
          width: 100%;

          span {
            display: inline-block;
            @include typo_16_light;
            line-height: pxToRem(21px);

            &:nth-child(1) {
              @include typo_16_regular;
              width: pxToRem(120px);
              line-height: pxToRem(21px);
            }
          }
        }
      }
    }
  }

  .product {
    &__title {
      @include typo_50_thin;
      margin-bottom: pxToRem(5px);
      color: $colorBlack;

      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      hyphens: auto;
      word-break: auto-phrase;
      word-wrap: break-word;
    }

    &__desc {
      @include typo_18_light;
      max-width: pxToRem(725px);
      margin-bottom: pxToRem(19px);
      color: $colorBlack;
    }

    &__price {
      position: relative;
      color: $colorBlack;

      @include typo_32_light;

      &.discount {
        color: $colorRed;
        padding-top: pxToRem(15px);
      }

      &-discount {
        position: absolute;
        left: 0;
        top: pxToRem(-5px);
        text-decoration: line-through;
        @include typo_14_regular;
        color: $colorBlack;
      }
    }

    &__controls {
      margin-top: pxToRem(20px);
      > div {
        display: flex;
        align-items: flex-start;
        gap: pxToRem(25px);

        button {
          &.favorite {
            margin-left: auto;
            svg {
              width: pxToRem(33px);
              height: pxToRem(33px);
            }
          }

          &.favorite:hover {
            svg {
              &:hover {
                path {
                  stroke: $colorLike;
                  fill: $colorWhite;
                }
              }
            }
          }
        }
      }
    }

    &__btn {
      display: flex;
      flex-direction: column;
      max-width: pxToRem(356px);
      width: 100%;

      gap: pxToRem(15px);
    }

    &__tooltip {
      display: flex;
      align-items: center;
      margin: 0 auto;
      gap: pxToRem(10px);
      cursor: pointer;
      transition: 0.3s;
      color: $colorBlack;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.tooltip {
  background: $colorWhite !important;
  opacity: 1 !important;
  border-radius: pxToRem(5px);
  border: pxToRem(1px) solid $colorGray-2;
  padding: pxToRem(10px) !important;
  width: pxToRem(580px) !important;
  color: $colorBlack !important;
  transition: 0;

  &__content {
    width: 100%;
    border-spacing: 0 pxToRem(5px);

    border-spacing: pxToRem(10px);
    thead {
      margin-bottom: pxToRem(15px);
    }

    tr {
      th {
        text-align: left;

        &:nth-child(2) {
          width: pxToRem(120px);
        }
      }
    }

    tbody {
      tr {
        &:not(:last-child) {
          margin-bottom: pxToRem(10px);
        }
      }
    }
  }

  :global(.react-tooltip-arrow) {
    background: $colorWhite !important;
    border: pxToRem(1px) solid $colorGray-2 !important;
    border-top: 0 !important;

    border-left: 0 !important;
  }
}

@media (max-width: 1024px) {
  .top {
    &__preview {
      width: 100%;
      max-width: 100%;
      height: pxToRem(307px);
      margin-bottom: pxToRem(40px);
    }

    &__info {
      padding-bottom: pxToRem(35px);
      max-width: 100%;

      .badge {
        margin-bottom: pxToRem(-13px);
      }

      &-row {
        max-width: 100%;
      }
      .product {
        &__title {
          @include typo_28_thin;
          max-width: 100%;
        }

        &__desc {
          @include typo_16_light;
          max-width: 100%;
        }

        &__counter {
          max-width: 100%;

          > * {
            width: 100%;
            > *:nth-child(1) {
              height: pxToRem(40px);
            }
          }
        }

        &__btn {
          max-width: 100%;
        }
      }

      .product {
        &__controls {
          margin-top: 0;

          > div {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: pxToRem(15px) pxToRem(10px);
            align-items: center;

            > * {
              width: calc(50% - 15px);
            }

            button {
              @include typo_12_medium;

              &.favorite {
                svg {
                  width: pxToRem(22px);
                  height: pxToRem(22px);
                }
              }
            }
          }
        }
      }
    }

    .additional {
      margin-top: 0;

      &__list {
        gap: pxToRem(10px);

        &-row {
          span {
            @include typo_14_light;
            line-height: pxToRem(20px);

            &:nth-child(1) {
              @include typo_14_regular;
              line-height: pxToRem(20px);
            }
          }
        }
      }
    }
  }

  .tooltip {
    width: 100% !important;
    max-width: calc(100% - 10px) !important;
    overflow-x: auto;
    overflow-y: auto;
    pointer-events: auto !important;
    z-index: 1;

    &__content {
      min-width: pxToRem(540px);
    }
  }

  .mobile__btn {
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .top {
    &__info {
      .product {
        &__controls {
          > div {
            .product__counter {
              width: calc(44% - pxToRem(5px));
            }
            .product__btn {
              width: calc(55% - pxToRem(5px));

              button {
                padding-left: pxToRem(5px);
                padding-right: pxToRem(5px);
              }
            }
          }
        }
      }

      .additional {
        &__list {
          flex-direction: column;

          &-col {
            &:nth-child(2) {
              .additional__list-row {
                span:nth-child(1) {
                  width: pxToRem(120px);
                }
              }
            }
          }
        }
      }
    }

    .product {
    }
  }
}
