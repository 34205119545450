@import "../../../styles/functions";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.box {
  width: 100%;
  max-width: pxToRem(450px);

  padding: pxToRem(45px) pxToRem(40px) pxToRem(35px);
  border-radius: pxToRem(5px);
  background: $colorMilky;

  .form {
    display: flex;
    flex-direction: column;
    position: relative;

    &__title {
      @include typo_50_light;
      color: $colorBlack;
      text-transform: uppercase;
      margin-bottom: pxToRem(4px);
    }

    &__desc {
      @include typo_14_regular;
      line-height: pxToRem(21px);
      color: $colorBlack;
      margin-bottom: pxToRem(40px);
    }

    &__submit {
      margin-top: pxToRem(32px);
    }

    &__link {
      margin-top: pxToRem(55px);

      * {
        @include typo_14_regular;
        line-height: pxToRem(21px);
      }
    }

    &__alert {
      color: $colorRed;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: pxToRem(10px);
      margin-top: pxToRem(10px);
      width: 100%;
      position: absolute;
      opacity: 0;
      transition: 0.3s;

      &.visible {
        opacity: 1;
      }
    }

    &__input {
      &.errors {
        input {
          border-color: $colorRed !important;
          color: $colorRed !important;
        }
      }
    }
  }

  :global(.react-code-input) {
    display: flex !important;
    gap: pxToRem(12px) !important;
    width: 100% !important;

    input {
      background: transparent !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      border-bottom: pxToRem(1px) solid $colorBlack !important;
      border-radius: 0 !important;
      padding: 0 !important;
      height: pxToRem(42px) !important;

      padding-bottom: pxToRem(10px) !important;

      width: calc((100% - ((var(--pin-size, 6) - 1) * pxToRem(12px))) / var(--pin-size, 6)) !important;
      max-width: pxToRem(52px);

      margin: 0 !important;

      font-family: "Roboto Light" !important;
      font-size: pxToRem(35px) !important;
      font-weight: 200 !important;
      // TODO: Fix safari font height
      // line-height: pxToRem(62px) !important;
      letter-spacing: pxToRem(2px) !important;
      text-align: center !important;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
        margin: 0 !important;
      }

      /* Firefox */
      &[type="number"] {
        -moz-appearance: textfield !important;
      }
    }
  }
}

@media (max-width: 767px) {
  .box {
    padding: pxToRem(30px) pxToRem(20px) pxToRem(15px);

    .form {
      &__title {
        @include typo_34_light;
        margin-bottom: pxToRem(15px);
      }

      &__desc {
        @include typo_12_regular;
        line-height: pxToRem(18px);
        letter-spacing: 0.02em;
        margin-bottom: pxToRem(15px);
      }

      &__submit {
        margin-top: pxToRem(20px);
      }

      &__link {
        margin-top: pxToRem(33px);

        * {
          @include typo_14_regular;
          line-height: pxToRem(21px);
        }
      }

      &__alert {
        align-items: flex-start;
        justify-content: flex-start;
        @include typo_10_medium;
        line-height: pxToRem(12px);
        letter-spacing: 0.02em;
        font-weight: 400;
        padding-top: pxToRem(3px);

        svg {
          margin-top: pxToRem(-3px);
        }
      }

      :global(.react-code-input) {
        input {
          height: pxToRem(37px) !important;
          padding-bottom: pxToRem(0px) !important;
          font-size: pxToRem(24px) !important;
          font-weight: 200 !important;
          line-height: pxToRem(32px) !important;
          letter-spacing: 0.02em !important;
        }
      }
    }
  }
}
