@import "../../../styles/functions";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.box {
  * {
    color: $colorBlack;
  }

  .text {
    &.row {
      display: flex;
      gap: pxToRem(68px);

      > * {
        width: 100%;
        & + * {
          width: 100%;
          max-width: pxToRem(912px);
          min-width: pxToRem(912px);
        }
      }
    }
  }

  .left,
  .right {
    display: flex;
    flex-direction: column;
  }

  h1 {
    @include typo_50_thin;
    text-transform: uppercase;
  }
  h2 {
    @include typo_32_light;
    text-transform: uppercase;
  }
  h3 {
    @include typo_26_regular;
    text-transform: uppercase;
  }
  h4 {
    @include typo_21_regular;
    text-transform: uppercase;
  }
  p {
    @include typo_16_regular;
    margin: pxToRem(30px) 0;
  }
  hr {
    margin: pxToRem(50px) 0;
  }
  a {
    @include hover_underline;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
  ul,
  ol {
    margin: 0;
    padding-left: pxToRem(15px);
    li {
      @include typo_16_regular;
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    display: block;
    max-width: 100%;
    overflow-x: auto;
    thead {
      tr {
        th {
          @include typo_16_medium;
          border-bottom: pxToRem(1px) solid $colorBlack;
          text-align: left;
          padding: pxToRem(4px);
          overflow: hidden;
        }
      }
    }
    tbody {
      tr {
        td {
          @include typo_16_regular;
          text-overflow: ellipsis;
          word-wrap: break-word;
          border-bottom: pxToRem(1px) solid $colorBlack;
          vertical-align: top;
          padding: pxToRem(4px) pxToRem(4px) pxToRem(5px) pxToRem(4px);
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .box {
    &__inner {
      > div {
      }
    }

    .text {
      &.row {
        display: flex;
        flex-direction: column;
        gap: pxToRem(30px);

        > * {
          width: 100%;
          & + * {
            width: 100%;
            max-width: 100%;
            min-width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .box {
  }
}
