@import "../../styles/functions";
@import "../../styles/variables";
@import "../../styles/mixins";

.modal {
  padding: pxToRem(40px) 0 0 0;

  .inputs {
    display: flex;
    flex-direction: column;
    gap: pxToRem(15px);
  }

  button {
    width: pxToRem(220px);
    float: right;
    margin: pxToRem(40px) 0 pxToRem(20px) 0;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  p {
    @include typo_18_light;

    @media (max-width: 768px) {
      padding-top: pxToRem(10px);
      @include typo_16_light;
    }
  }
}

@media screen and (max-width: 768px) {
  .modal {
    padding: pxToRem(20px) 0 0 0;

    .inputs {
      max-height: pxToRem(380px);
      overflow-y: auto;
      margin-bottom: pxToRem(30px);
    }
  }
}
