@import "../../../styles/functions";
@import "../../../styles/variables.scss";
@import "../../../styles/mixins";

.box {
  width: pxToRem(372px);
  height: pxToRem(320px);
  border: pxToRem(1px) solid transparent;
  border-radius: pxToRem(5px);
  overflow: hidden;
  transition: 0.3s;

  color: $colorBlack;

  &.image-error {
    .box {
      &__inner {
        &::after {
          transition: 0;
          background: transparent;
        }
      }
    }

    .title {
      color: $colorBlack;
    }

    .image {
      background: $colorMilky;
    }
  }

  &__inner {
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;

    position: relative;

    &::after {
      transition: 0.3s;
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #00000080;
    }
  }

  .content {
    position: relative;
    z-index: 1;
    padding: pxToRem(30px) pxToRem(45px) 0;

    color: $colorWhite;
  }

  .title {
    @include typo_42_thin;
  }

  .image {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: pxToRem(5px);
    }
  }

  .link {
    display: inline-block;
    margin-top: pxToRem(6px);
    @include typo_16_regular;
    transition: 0.3s;
    opacity: 0;
    pointer-events: none;
    border-bottom: pxToRem(1px) solid transparent;

    @include hover_underline(-3px);
  }

  &:hover {
    box-shadow: 0px 0px pxToRem(25px) 0px #00000026;
    border: pxToRem(1px) solid $colorWhite;

    .link {
      opacity: 1;
      pointer-events: all;
    }

    .box {
      &__inner {
        &::after {
          background: #ffffff;
        }
      }
    }

    .content {
      color: $colorBlack;
    }
  }
}

@media (max-width: 1024px) {
  .box {
    width: pxToRem(270px);
    height: pxToRem(270px);

    &:hover {
      box-shadow: 0px 0px pxToRem(25px) 0px #00000026;
      border: pxToRem(1px) solid $colorWhite;

      .box {
        &__inner {
          &::after {
            background: rgba(0, 0, 0, 0.2509803922);
          }
        }
      }

      .content {
        color: $colorWhite;
      }
    }

    &.image-error {
      .link {
        color: $colorBlack;
      }
    }

    .link {
      opacity: 1;
      pointer-events: all;
      color: $colorWhite;
      @include typo_14_regular;
      line-height: pxToRem(17px);
    }

    .content {
      position: relative;
      z-index: 1;
      padding: pxToRem(38px) pxToRem(15px) 0 pxToRem(24px);

      color: $colorWhite;
    }

    .title {
      @include typo_24_thin;
      line-height: pxToRem(32px);
    }
  }
}

@media (max-width: 767px) {
  .box {
    width: pxToRem(190px);
    height: pxToRem(190px);
  }
}
