@mixin typo_62_thin {
  font-family: "Roboto Thin";
  font-size: pxToRem(62px);
  font-style: normal;
  font-weight: 100;
  line-height: pxToRem(72px);
  letter-spacing: 0.02em;
}

@mixin typo_50_light {
  font-family: "Roboto Light";
  font-size: pxToRem(50px);
  font-style: normal;
  font-weight: 300;
  line-height: pxToRem(62px);
  letter-spacing: 0.02em;
}

@mixin typo_50_thin {
  font-family: "Roboto Thin";
  font-size: pxToRem(50px);
  font-style: normal;
  font-weight: 100;
  line-height: pxToRem(62px);
  letter-spacing: 0.02em;
}

@mixin typo_42_thin {
  font-family: "Roboto Thin";
  font-size: pxToRem(42px);
  font-style: normal;
  font-weight: 100;
  line-height: pxToRem(42px);

  letter-spacing: 0.02em;
}

@mixin typo_36_thin {
  font-family: "Roboto Thin";
  font-size: pxToRem(36px);
  font-weight: 100;
  line-height: pxToRem(42px);
  letter-spacing: 0em;
  text-align: left;
}

@mixin typo_35_light {
  font-family: "Roboto Light";
  font-size: pxToRem(35px);
  font-style: normal;
  font-weight: 300;
  line-height: pxToRem(62px);
}

@mixin typo_34_thin {
  font-family: "Roboto Thin";
  font-size: pxToRem(34px);
  font-style: normal;
  font-weight: 100;
  line-height: pxToRem(38px);
  letter-spacing: 0.02em;
}

@mixin typo_34_light {
  font-family: "Roboto Light";
  font-size: pxToRem(34px);
  font-style: normal;
  font-weight: 300;
  line-height: pxToRem(38px);
  letter-spacing: 0.02em;
}

@mixin typo_32_light {
  font-family: "Roboto Light";
  font-size: pxToRem(32px);
  font-style: normal;
  font-weight: 300;
  line-height: pxToRem(42px);
}

@mixin typo_32_thin {
  font-family: "Roboto Thin";
  font-size: pxToRem(32px);
  font-style: normal;
  font-weight: 100;
  line-height: pxToRem(32px);
}

@mixin typo_28_thin {
  font-family: "Roboto Thin";
  font-size: pxToRem(28px);
  font-style: normal;
  font-weight: 100;
  line-height: pxToRem(36px);
  letter-spacing: 0.02em;
}

@mixin typo_26_thin {
  font-family: "Roboto Thin";
  font-size: pxToRem(26px);
  font-style: normal;
  font-weight: 100;
  line-height: pxToRem(30.47px);
}

@mixin typo_26_light {
  font-family: "Roboto Light";
  font-size: pxToRem(26px);
  font-style: normal;
  font-weight: 300;
  line-height: pxToRem(36px);
}

@mixin typo_26_regular {
  font-family: "Roboto Regular";
  font-size: pxToRem(26px);
  font-style: normal;
  font-weight: 400;
  line-height: pxToRem(30px);
  letter-spacing: 0.02em;
}

@mixin typo_26_medium {
  font-family: "Roboto Medium";
  font-size: pxToRem(26px);
  font-style: normal;
  line-height: pxToRem(30px);
  font-weight: 500;
  letter-spacing: 0.02em;
}

@mixin typo_24_light {
  font-family: "Roboto Light";
  font-size: pxToRem(24px);
  font-weight: 300;
  line-height: pxToRem(32px);
  letter-spacing: 0em;
  text-align: left;
}

@mixin typo_24_thin {
  font-family: "Roboto Thin";
  font-size: pxToRem(24px);
  font-weight: 100;
  line-height: pxToRem(32px);
  letter-spacing: 0.02em;
}

@mixin typo_21_medium {
  font-family: "Roboto Medium";
  font-size: pxToRem(21px);
  font-weight: 500;
  line-height: pxToRem(28px);
  letter-spacing: 0.02em;
  text-align: left;
}

@mixin typo_21_regular {
  font-family: "Roboto Regular";
  font-size: pxToRem(21px);
  font-weight: 400;
  line-height: pxToRem(28px);
  letter-spacing: 0.02em;
  text-align: left;
}

@mixin typo_21_light {
  font-family: "Roboto Light";
  font-style: normal;
  font-weight: 300;
  font-size: pxToRem(21px);
  line-height: pxToRem(28px);
  letter-spacing: 0.02em;
}

@mixin typo_18_light {
  font-family: "Roboto Light";
  font-size: pxToRem(18px);
  font-style: normal;
  font-weight: 300;
  line-height: pxToRem(28px);
  letter-spacing: 0.02em;
}

@mixin typo_18_regular {
  font-family: "Roboto Regular";
  font-size: pxToRem(18px);
  font-style: normal;
  font-weight: 400;
  line-height: pxToRem(18.75px);
  letter-spacing: 1%;
}

@mixin typo_18_medium {
  font-family: "Roboto Medium";
  font-size: pxToRem(18px);
  font-style: normal;
  font-weight: 500;
  line-height: pxToRem(26px);
  letter-spacing: 0.02em;
}

@mixin typo_16_light {
  font-family: "Roboto Light";
  font-size: pxToRem(16px);
  font-style: normal;
  font-weight: 300;
  line-height: pxToRem(21px);
  letter-spacing: 0.02em;
}

@mixin typo_16_thin {
  font-family: "Roboto Thin";
  font-size: pxToRem(16px);
  font-style: normal;
  font-weight: 100;
  line-height: pxToRem(21px);
}

@mixin typo_16_regular {
  font-family: "Roboto Regular";
  font-size: pxToRem(16px);
  font-style: normal;
  font-weight: 400;
  line-height: pxToRem(30px);
  letter-spacing: 0.02em;
}

@mixin typo_16_medium {
  font-family: "Roboto Medium";
  font-size: pxToRem(16px);
  font-style: normal;
  font-weight: 500;
  line-height: pxToRem(30px);
  letter-spacing: 0.02em;
}

@mixin typo_14_regular {
  font-family: "Roboto Regular";
  font-size: pxToRem(14px);
  font-style: normal;
  font-weight: 400;
  line-height: pxToRem(30px);
}

@mixin typo_14_medium {
  font-family: "Roboto Medium";
  font-size: pxToRem(14px);
  font-style: normal;
  font-weight: 500;
  line-height: pxToRem(20px);
  letter-spacing: 0.02em;
}

@mixin typo_14_light {
  font-family: "Roboto Light";
  font-size: pxToRem(14px);
  font-style: normal;
  font-weight: 300;
  line-height: pxToRem(20px);
  letter-spacing: 0.02em;
}

@mixin typo_12_regular {
  font-family: "Roboto Regular";
  font-size: pxToRem(12px);
  font-style: normal;
  font-weight: 400;
  line-height: pxToRem(20px);
  letter-spacing: 0.02em;
}

@mixin typo_12_medium {
  font-family: "Roboto Medium";
  font-size: pxToRem(12px);
  font-style: normal;
  font-weight: 500;
  line-height: pxToRem(20px);
}

@mixin typo_12_light {
  font-family: "Roboto Light";
  font-size: pxToRem(12px);
  font-style: normal;
  font-weight: 300;
  line-height: pxToRem(14.06px);
}

@mixin typo_11_medium {
  font-family: "Roboto Medium";
  font-size: pxToRem(11px);
  font-style: normal;
  font-weight: 500;
  line-height: pxToRem(21px);
}

@mixin typo_10_medium {
  font-family: "Roboto Medium";
  font-size: pxToRem(10px);
  font-style: normal;
  font-weight: 500;
  line-height: pxToRem(20px);
}

@mixin typo_10_light {
  font-family: "Roboto Light";
  font-size: pxToRem(10px);
  font-style: normal;
  font-weight: 300;
  line-height: pxToRem(16px);
  letter-spacing: 0.02em;
}

@mixin typo_10_regular {
  font-family: "Roboto Regular";
  font-size: pxToRem(10px);
  font-style: normal;
  font-weight: 400;
  line-height: pxToRem(20px);
}

@mixin text_overflow_elipses($lineClamp: 3) {
  overflow: hidden;
  -webkit-line-clamp: $lineClamp;
  -webkit-box-orient: vertical;
  display: -webkit-box;

  @if $lineClamp == 1 {
    white-space: nowrap;
  } @else {
    white-space: normal;
  }
}

@mixin clear_scrollbar_styles() {
  &::-webkit-scrollbar {
    width: 0;
    padding-bottom: 0 !important;
    height: 0 !important;
    padding-bottom: 0 !important;
    height: 0 !important;
    background: transparent !important;
    display: none;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0;
    padding-bottom: 0 !important;
    height: 0 !important;
    padding-bottom: 0 !important;
    height: 0 !important;
    background: transparent !important;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent !important;
    border-radius: 0;
    overflow: hidden;
    width: 0;
    padding-bottom: 0 !important;
    height: 0 !important;
    padding-bottom: 0 !important;
    height: 0 !important;
  }

  &::-webkit-scrollbar {
    width: 0;
    padding-bottom: 0 !important;
    height: 0 !important;
    padding-bottom: 0 !important;
    height: 0 !important;
    background: transparent !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    width: 0;
    padding-bottom: 0 !important;
    height: 0 !important;
    padding-bottom: 0 !important;
    height: 0 !important;
    background: transparent !important;
  }
}

@mixin hover_underline($bottom: -5px, $color: $colorBlack) {
  position: relative;

  &::after {
    position: absolute;
    content: "";
    bottom: pxToRem($bottom);
    left: 0;
    width: 0;
    height: pxToRem(1px);
    background: $color;
    transition: 0.3s;
  }

  &:hover {
    &::after {
      width: 100%;
    }
  }
}
