@import "../../../styles/functions";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.mobile-filters {
  padding: 0 pxToRem(20px) pxToRem(10px);
  height: 100%;

  &__header {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: pxToRem(10px) 0;
  }

  &__close {
    margin-left: auto;
  }

  &__body {
    display: flex;
    flex-direction: column;

    gap: pxToRem(30px);
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - pxToRem(73px));
    padding-bottom: pxToRem(60px);

    @include clear_scrollbar_styles;

    > * {
      width: 100%;

      > * > * {
        max-width: 100%;
      }
    }

    &-sort {
      gap: pxToRem(0px);
    }
  }

  &__sort-btn {
    width: 100%;
    min-height: pxToRem(50px);
    display: flex;
    align-items: center;

    span {
      @include typo_16_light;
    }

    &:hover {
      span {
        @include hover_underline;
      }
    }
  }
}
