@import "../../styles/functions";
@import "../../styles/variables.scss";
@import "../../styles/mixins";

.box {
  background: var(--badge-color);
  border-radius: pxToRem(34px);
  padding: pxToRem(7px) pxToRem(0px);
  color: $colorWhite;
  width: pxToRem(59px);

  font-family: "Roboto Bold";
  font-size: pxToRem(16px);
  font-weight: 700;
  line-height: pxToRem(14px);
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;

  // product card view
  &.sm {
    border-radius: pxToRem(14px);
    padding: pxToRem(3px) pxToRem(5px);
    font-size: pxToRem(7px);
    font-weight: 700;
    line-height: pxToRem(6px);
    width: auto;
  }

  // product detail page view || product card tablet view
  &.sm-2 {
    border-radius: pxToRem(14px);
    padding: pxToRem(3px) pxToRem(5px);
    font-size: pxToRem(12px);
    line-height: pxToRem(14px);
    width: pxToRem(40px);
  }
}

@media (max-width: 1024px) {
  .box {
  }
}
