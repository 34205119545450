@import "../../../styles/functions";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.box {
  // min-height: pxToRem(798px);
  padding: pxToRem(125px) pxToRem(45px);
  position: relative;

  background: $colorMilky;

  &__inner {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__content {
  }

  .close {
    width: pxToRem(58px);
    height: pxToRem(58px);
    position: absolute;
    right: pxToRem(25px);
    top: pxToRem(25px);

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 1024px) {
  .box {
    &__inner {
    }

    .title {
    }

    .text {
    }

    .list {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 15px;

      > * {
        max-width: 100%;
        width: 100%;
      }
    }

    .close {
      width: pxToRem(48px);
      height: pxToRem(48px);
      position: absolute;
      right: pxToRem(10px);
      top: pxToRem(5px);

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 767px) {
  .box {
    padding-top: pxToRem(65px);
    padding-left: pxToRem(18px);
    padding-right: pxToRem(18px);
    padding-bottom: pxToRem(44px);
  }
}
