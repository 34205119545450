@import "../../../styles/functions";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.container {
  position: relative;
  width: 100%;
}

.modal {
  button {
    width: pxToRem(220px);
    float: right;
    margin: pxToRem(40px) 0 pxToRem(20px) 0;
  }
  p {
    @include typo_18_light;
  }
}

.box {
  width: 100%;

  display: flex;
  column-gap: pxToRem(128px);
  row-gap: pxToRem(25px);
  padding: pxToRem(40px) 0 pxToRem(44px) 0;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    row-gap: pxToRem(25px);
    padding: pxToRem(40px) 0 pxToRem(9px) 0;
  }

  &__left,
  &__right {
    :last-child {
      &:first-child {
        // color: $colorBlack;
      }
    }
    .title {
      font-family: "Roboto Light";
      font-size: pxToRem(32px);
      font-weight: 200;
      line-height: pxToRem(38px);
      letter-spacing: 0em;
      text-align: left;
      color: $colorBlack;
      padding-bottom: pxToRem(40px);

      @media (max-width: 768px) {
        font-size: pxToRem(24px);
        line-height: pxToRem(32px);
        padding-bottom: pxToRem(20px);
      }
    }
    .add {
      margin-top: pxToRem(40px);
      max-width: pxToRem(260px);
      width: 100%;
    }
  }

  > div {
    width: calc(50% - pxToRem(64px));

    @media (max-width: 1200px) {
      width: 100%;
    }
  }
}

.field {
  flex-direction: column;
  column-gap: pxToRem(5px);

  .title {
    padding-bottom: pxToRem(8px);
    font-family: "Roboto Regular";
    font-size: pxToRem(12px);
    font-weight: 400;
    line-height: pxToRem(18px);
    letter-spacing: 0.02em;
    text-align: left;
    color: $colorGray-3;
  }

  .description {
    padding-top: pxToRem(5px);
    font-family: "Roboto Regular";
    font-size: pxToRem(16px);
    font-weight: 400;
    line-height: pxToRem(21px);
    letter-spacing: 0.02em;
    text-align: left;
    color: $colorBlack;
    padding-bottom: pxToRem(20px);
    border-bottom: 1px solid $colorLightGray;
  }
}

.button {
  cursor: pointer;
  font-family: "Roboto Regular";
  font-size: pxToRem(12px);
  font-weight: 400;
  line-height: pxToRem(18px);
  letter-spacing: 0.02em;
  text-align: left;
  color: $colorBlack;
  display: inline;
  transition: opacity 0.3s;
  padding: pxToRem(2.5px) 0;
  border-bottom: pxToRem(1px) solid $colorGray;

  &:hover {
    opacity: 0.5;
  }
  @media (max-width: 768px) {
    padding-top: pxToRem(40px);
    display: block;
    width: max-content;
  }
}

.accardion-header {
  padding-top: pxToRem(10px);
  font-family: "Roboto Regular";
  font-size: pxToRem(18px);
  font-weight: 400;
  line-height: pxToRem(28px);
  letter-spacing: 0.01em;
  text-align: left;
  transition: transform 0.3s;

  &.animated {
    transform: translateY(pxToRem(30px));
  }
}

.accordion-body {
  padding: pxToRem(0px) 0 pxToRem(25px);
  position: relative;

  &.padding-top {
    padding: pxToRem(20px) 0 pxToRem(25px);
  }

  .button {
    display: block;
    width: max-content;
    padding-top: pxToRem(25px);
  }

  svg {
    cursor: pointer;
    transition: 0.3s;

    @media (max-width: 768px) {
      width: pxToRem(20px);
      width: pxToRem(20px);
    }
  }
}

.bold {
  @include typo_18_regular;
  line-height: pxToRem(28px);
  letter-spacing: 0.01em;
  margin-bottom: pxToRem(10px);
}

.text {
  font-family: "Roboto Light";
  font-size: pxToRem(16px);
  font-weight: 300;
  line-height: pxToRem(21px);
  letter-spacing: 0.01em;
  text-align: left;
  color: $colorBlack;
}

.buttons {
  display: flex;
  align-items: center;
  gap: pxToRem(15px);
  padding: pxToRem(15px) 0 0 0;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      opacity: 0.5;
    }

    &:not(:disabled):hover {
      circle {
        fill: $colorBlack;
      }
      path {
        stroke: $colorWhite;
      }
    }
  }
}

.remove,
.edit-address {
  width: min-content;
  cursor: pointer;
}

.edit-address {
  border: pxToRem(1px) solid $colorBlack;
  border-radius: 50%;
  width: pxToRem(28px);
  height: pxToRem(28px);
  transition: 0.3s;

  &:not(:disabled):hover {
    background: $colorBlack;

    circle {
      fill: $colorBlack;
    }
    path {
      stroke: $colorWhite;
    }
  }
}

.switch {
  position: absolute;
  margin-top: pxToRem(10px);

  label,
  span {
    color: $colorBlack;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .company-tab {
    > div {
      flex-direction: column-reverse;
    }
  }
}

@media (max-width: 768px) {
  .company-tab {
    > div {
      flex-direction: column-reverse;
    }
  }

  .box {
    padding-top: pxToRem(35px);
    &__left,
    &__right {
      .add {
        max-width: 100%;
      }
      .title {
        padding-bottom: pxToRem(15px);
      }
    }
  }

  .remove {
    svg {
      width: pxToRem(30px);
      height: pxToRem(30px);
    }
  }
}
