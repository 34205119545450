@import "../../../styles/functions";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: $colorBlack;

  .title {
    &,
    * {
      @include typo_50_thin;
    }
    max-width: pxToRem(234px);
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 1024px) {
  .box {
    display: flex;
    flex-direction: column;

    .title {
      &,
      * {
        @include typo_28_thin;
      }
      max-width: 100%;
      display: flex;
      flex-direction: row;
      gap: pxToRem(4px);
      margin-bottom: pxToRem(25px);
      margin-top: pxToRem(25px);
    }
  }
}
