@import "../../../styles/functions";
@import "../../../styles/variables.scss";
@import "../../../styles/mixins";

.box {
  background: var(--background-color, transparent);
  max-height: pxToRem(600px);
  overflow-y: auto;

  &.padding-bottom {
    padding-bottom: pxToRem(10px);
  }

  &__item {
    padding: pxToRem(17px) pxToRem(20px);

    > * {
      border-bottom: 1px solid transparent;
    }

    &,
    * {
      @include typo_18_medium;
      cursor: pointer;
    }

    & {
      cursor: auto;
    }

    &.sm {
      &,
      &-light {
        height: pxToRem(40px);
        padding: 0 pxToRem(20px);
        display: flex;
        align-items: center;
      }

      &-light {
        &,
        * {
          @include typo_16_light;
        }
      }
    }

    &.hover {
      > * {
        @include hover_underline;
      }
    }

    * {
      text-align: left;
    }
  }

  .emtpy {
    display: flex;
    padding: pxToRem(17px) pxToRem(20px);
    @include typo_16_light;
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 1024px) {
  .box {
    max-height: pxToRem(250px);
  }
}
