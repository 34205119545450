@import "../../styles/functions";
@import "../../styles/variables";
@import "../../styles/mixins";

.tab {
  position: relative;
  span {
    font-family: "Roboto Regular";
    cursor: pointer;
    font-size: pxToRem(18px);
    font-weight: 400;
    line-height: pxToRem(26px);
    letter-spacing: 0.02em;
    text-align: left;
    color: $colorGray;

    justify-content: center;
    flex-direction: column;
    display: flex;
    width: min-content;
    @media (min-width: 991px) {
      width: max-content;
    }

    @media (max-width: 768px) {
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: min-content;
      @include typo_14_medium;
    }
  }

  > span > span {
    position: relative;

    &::before {
      position: absolute;
      top: pxToRem(50px);
      width: 100%;
      height: pxToRem(1px);
      content: " ";
      background-color: $colorBlack;
      opacity: 0;
      transition: opacity 0.3s;

      @media (max-width: 768px) {
        top: pxToRem(52.5px);
      }
      @media (min-width: 769px) and (max-width: 991px) {
        top: pxToRem(52.5px);
      }
    }
  }

  &:hover {
    > span > span {
      &::before {
        opacity: 1 !important;
      }
    }
  }

  &__active {
    span {
      color: $colorBlack;
      @media (max-width: 768px) {
        height: 51.5px;
      }
      &::before {
        opacity: 1 !important;
      }
    }
  }
}

.tabs {
  width: 100%;

  &__buttons {
    display: flex;
    gap: pxToRem(128px);
    padding: pxToRem(23.5px) 0;
    border-bottom: pxToRem(1px) solid $colorGrayTab;

    @media (max-width: 768px) {
      padding: 0;
      gap: 0;
      justify-content: space-between;
    }
    @media (min-width: 769px) and (max-width: 991px) {
      padding: 0;
    }

    > div {
      width: 50%;
      @media (max-width: 768px) {
        width: min-content;
        min-width: calc(50% - pxToRem(20px));
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .tab {
    &__active {
      .tab-button {
        height: 100%;
        width: 100%;
        display: block;
        height: 100%;
        position: relative;
        min-height: pxToRem(60px);

        &::before {
          bottom: 0;
          top: unset;
        }
      }
    }

    .tab-button {
      width: 100%;

      span {
        width: 100%;

        &::before {
          display: none;
        }
      }
    }
  }
}
