@import "../../styles/functions";
@import "../../styles/variables.scss";
@import "../../styles/mixins";

.box {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;

  &.right {
    input {
      & ~ label {
        padding-right: pxToRem(20px);
        padding-left: pxToRem(0px);

        &::after {
          left: unset;
          right: pxToRem(11px);
          top: pxToRem(5px);
        }

        &::before {
          left: unset;
          right: pxToRem(6px);
          top: 0;
        }

        &:not(:empty) {
          padding-left: pxToRem(0px);
          padding-right: pxToRem(31px);
        }
      }
    }
  }

  input {
    position: absolute;
    left: pxToRem(-9999px);
    width: inherit;
    height: inherit;

    & ~ label {
      display: flex;
      align-items: center;
      position: relative;
      display: block;
      min-height: pxToRem(18px);
      padding-left: pxToRem(20px);
      cursor: pointer;
      width: 100%;

      color: $colorBlack;

      @include typo_16_regular;

      & a {
        color: $colorBlack;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        background: $colorLightGray;
        top: calc(50% - pxToRem(6px));
        left: pxToRem(3px);
        width: pxToRem(10px);
        height: pxToRem(10px);
        max-height: pxToRem(18px);
        opacity: 0;
        transition: opacity 0.2s ease;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        background: transparent;
        top: calc(50% - pxToRem(9px));
        left: 0px;
        width: pxToRem(18px);
        height: pxToRem(18px);
        max-height: pxToRem(18px);
        border: pxToRem(1px) solid $colorBlack;
        border-radius: 50%;
        transition: all 0.2s ease;
      }

      &:hover {
        &::before {
        }
      }

      &:not(:empty) {
        margin: 0;
        padding-left: pxToRem(31px);
      }
    }

    &:disabled {
      & ~ label {
        &::after {
          opacity: 0;
        }
      }
    }

    &:checked {
      & ~ label {
        color: $colorBlack;

        &::before {
          border: pxToRem(1px) solid $colorBlack;
          background: $colorBlack;
        }

        &::after {
          opacity: 1;
          background: url(../../../public/svg/check.svg) no-repeat center center;
          background-size: contain;
        }
      }
    }
  }

  &:hover {
    input {
      & ~ label {
        &::before {
          border: pxToRem(1px) solid $colorGray;
        }
      }
    }
  }
  &.invalid {
    input {
      & ~ label {
        color: $colorBlack;

        & a {
          color: $colorBlack;
        }

        &::after {
          border-color: $colorBlack;
        }

        &::before {
          border: pxToRem(1px) solid $colorBlack;
        }
      }
    }
  }
}
