@import "../../styles/functions";
@import "../../styles/variables.scss";
@import "../../styles/mixins";

.container {
  display: flex;
  justify-content: center;
  div:first-child {
    max-width: pxToRem(80px);
  }
}

.loader {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: pxToRem(30px);
    height: pxToRem(30px);
    top: calc(50% - pxToRem(15px));
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: pxToRem(22px);
    height: pxToRem(22px);
    margin: pxToRem(3px);
    border: pxToRem(3px) solid $colorBlack;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $colorBlack transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
