$colorWhite: #ffffff;
$colorBlack: #333333;
$colorGray: #aaaaaa;
$colorGray-2: #d9d9d9;
$colorLightGray: #e2e2e2;
$colorBlue: #a0aed8;
$colorDarkBlue: #00202d;
$colorLightGreen: #d5e4d5;
$colorMilky: #f8f1e9;
$colorLike: #ffb7b7;
$colorRed: #eb5757;

$colorGreen: #6fcf97;

$colorGray-3: #828282;
$colorGrayTab: #e2e2e2;

$borderRadiusBig: pxToRem(50px);
$borderRadiusSmall: pxToRem(25px);
