@import "../../../../styles/functions";
@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: pxToRem(1156px);
  padding-bottom: pxToRem(15px);

  border: pxToRem(1px) solid $colorLightGray;
  border-radius: pxToRem(5px);

  .row {
    display: flex;
    width: 100%;
    padding: pxToRem(5px) pxToRem(38px);

    &:not(:last-child) {
      border-bottom: pxToRem(1px) solid $colorLightGray;
    }
  }

  .col {
    padding: pxToRem(19px) pxToRem(0px);
    max-width: calc(pxToRem(239px) + pxToRem(40px));
    padding-right: pxToRem(40px);

    width: 100%;

    &.center {
      display: flex;
      align-items: center;
      position: relative;
      a {
        position: absolute;
        right: pxToRem(-8px);
        top: calc(50% - pxToRem(10px));
      }
    }

    &.required {
      .col {
        &__title {
          &::after {
            content: "*";
          }
        }
      }
    }

    &:last-child {
      max-width: calc(pxToRem(239px));
      padding-right: 0;
    }

    &__title {
      @include typo_12_medium;
      margin-bottom: pxToRem(4px);
    }

    &__content {
      @include typo_16_light;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: pxToRem(31px);
    }

    .big-text {
      @include typo_26_light;
      line-height: pxToRem(30px);
    }

    .download {
      margin: auto auto;
    }

    .other-btn {
      display: flex;
      align-items: center;
      gap: pxToRem(15px);

      span {
        @include typo_12_regular;
        border-bottom: pxToRem(1px) solid $colorGray;
        opacity: 1;
        transition: 0.3s;
        color: $colorBlack;

        &:hover {
          opacity: 0.5;
        }
      }

      svg {
        cursor: pointer;
        path {
          transition: 0.3s;
        }
        &:hover {
          path {
            fill: $colorBlack;
            stroke: $colorWhite;
          }
        }
      }
    }
  }

  .select {
    position: relative;
    width: 100%;
    height: 100%;
    > * {
      position: absolute;
      z-index: 1;
    }
  }
}

.address {
  display: flex;
  flex-direction: column;
}

.form__alert {
  color: $colorRed;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: pxToRem(10px);
  margin-top: pxToRem(0px);
  position: absolute;
  opacity: 0;
  transition: 0.3s;

  @include typo_14_light;

  &.visible {
    opacity: 1;
  }
}
