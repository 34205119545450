@import "../../styles/functions";
@import "../../styles/variables";
@import "../../styles/mixins";

.content {
  width: 100%;
  height: 100%;
  padding-top: pxToRem(40px);
  display: flex;
  flex-direction: column;

  .grid {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // gap: pxToRem(20px);
    display: flex;
    flex-direction: column;
    gap: pxToRem(10px);

    .col {
      display: flex;
      flex-direction: column;
      gap: pxToRem(10px);
    }

    .header {
      position: relative;
      height: pxToRem(40px);
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: pxToRem(20px);

      .col {
        position: relative;
        > * {
          position: absolute;
        }

        &:nth-child(2) {
          > * {
            z-index: 1;
          }
        }
      }
    }

    .body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0 pxToRem(20px);
      max-height: pxToRem(201px);
      overflow-y: auto;

      .col {
        background: $colorMilky;
        border: pxToRem(1px) solid $colorLightGray;
        border-top: 0;

        &.first {
          border-radius: pxToRem(5px) pxToRem(5px) 0 0;
          border-bottom: pxToRem(1px) solid $colorLightGray;
          border-top: pxToRem(1px) solid $colorLightGray;
        }

        &.last {
          border-radius: 0 0 pxToRem(5px) pxToRem(5px);
        }

        &.invalid {
          .item {
            @include typo_16_regular;
            color: $colorRed;
          }
        }
      }

      .item {
        padding: pxToRem(9px) pxToRem(20px);

        @include typo_16_thin;

        &:not(:last-child) {
          border-bottom: pxToRem(1px) solid $colorLightGray;
        }
      }
    }
  }

  .footer {
    margin-top: auto;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .error {
    @include typo_12_light;
    display: flex;
    align-items: center;
    gap: pxToRem(10px);

    opacity: 0;
    transition: 0.3s;

    &.visible {
      opacity: 1;
    }
  }

  .btn {
    margin-left: auto;
    button {
      width: pxToRem(230px);
    }
  }
}
