@import "../../../styles/functions";
@import "../../../styles/variables.scss";
@import "../../../styles/mixins";

.box {
  width: 100%;
  height: 100%;

  padding: 0 pxToRem(20px);
  background: var(--product-slider-bg, "transparent");

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;

  .slider {
    max-width: pxToRem(1920px);
    margin: 0 auto;
    width: pxToRem(495px);
    height: 100%;
    position: relative;

    &:hover {
      :global(.swiper-button-disabled) {
        opacity: 0.5;
      }
    }

    img {
      width: pxToRem(495px);
      height: pxToRem(530px);
      object-fit: contain;
    }
  }

  .expand {
    position: absolute;
    right: pxToRem(20px);
    top: pxToRem(20px);
    cursor: pointer;
    z-index: 100;

    transition: 0.3s;

    &:hover {
      svg {
        path {
          stroke: $colorBlack;
        }
      }
    }
  }

  .empty {
    width: 100%;

    img {
      width: 100%;
    }
  }
}

.button_prev,
.button_next {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: pxToRem(20px);
  height: pxToRem(35px);
  border: 0;
  outline: none;
  z-index: 10;
  top: calc(50% - pxToRem(20px));
  transition: opacity 0.3s;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }
}

.button_prev {
  left: pxToRem(20px);
}

.button_next {
  right: pxToRem(20px);
  transform: rotate(-180deg);
}

:global(.preview-slider) {
  :global(.swiper) {
    width: 100%;
    height: 100%;
    border-radius: pxToRem(5px);
    overflow: hidden;
    :global(.swiper-wrapper) {
      height: 100%;
    }

    :global(.swiper-slide) {
      height: 100%;
      border-radius: pxToRem(5px);
      overflow: hidden;
      width: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  :global(.swiper-pagination) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: pxToRem(5px);
    bottom: pxToRem(30px) !important;
    z-index: 2;
  }

  :global(.swiper-pagination-bullet) {
    width: pxToRem(30px);
    height: pxToRem(2px);
    border-radius: pxToRem(50px);
    background: $colorGray;
    opacity: 1;
    margin: 0px !important;
  }

  :global(.swiper-pagination-bullet-active) {
    background: $colorBlack;
  }

  :global(.swiper-button-disabled) {
    svg {
      path {
        stroke: rgba(170, 170, 170, 1);
      }
    }
  }
}

.modal__slider {
  height: 100%;
  // max-height: pxToRem(750px);
  max-height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;

  .button_prev {
    left: 0px;
  }

  .button_next {
    right: 0px;
  }

  :global(.swiper) {
    position: unset;

    :global(.swiper-slide) {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: calc(100% - pxToRem(40px));
        height: 100%;
        object-fit: contain;
      }
    }
  }

  :global(.swiper-pagination) {
    bottom: pxToRem(10px);
  }
}

@media (max-width: 1024px) {
  .box {
    padding: 0 pxToRem(5px);

    .slider {
      max-width: 100%;
      width: 100%;

      img {
        width: pxToRem(240px);
        height: pxToRem(260px);
      }
    }

    .button_prev,
    .button_next {
      width: pxToRem(11px);
      height: pxToRem(20px);
      top: calc(50% - pxToRem(10px));
    }

    .button_prev {
      left: pxToRem(5px);
    }

    .button_next {
      right: pxToRem(5px);
    }
  }

  :global(.preview-slider) {
    :global(.swiper-pagination) {
      bottom: pxToRem(0px) !important;
      z-index: 2;
    }
  }
}
