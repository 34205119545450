@import "../../styles/functions";
@import "../../styles/variables.scss";
@import "../../styles/mixins";

.box {
  min-width: pxToRem(137px);
  width: pxToRem(137px);
  position: relative;

  &.disabled {
    pointer-events: none;
  }

  &__inner {
    height: pxToRem(40px);
    display: flex;
    align-items: center;

    &:hover {
      & + .pack-info {
        opacity: 1;
      }
    }
  }

  input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }

    background: transparent;
    border: pxToRem(1px) solid $colorBlack;
    height: 100%;
    outline: none;
    border-left: 0;
    border-right: 0;
    border-radius: 0;

    text-align: center;
    padding: 0 pxToRem(4px);

    width: pxToRem(67px);

    @include typo_14_regular;
  }

  .btn {
    width: pxToRem(35px);
    min-width: pxToRem(35px);
    height: 100%;
    border: pxToRem(1px) solid $colorBlack;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: background 0.3s;

    &.decrement {
      border-radius: pxToRem(25px) 0px 0px pxToRem(25px);
    }

    &.increment {
      border-radius: 0px pxToRem(25px) pxToRem(25px) 0px;
    }

    svg {
      path {
        transition: 0s;
      }
    }
    &:hover {
      background: $colorBlack;
      color: $colorWhite;

      svg {
        path {
          fill: $colorWhite;
          stroke: $colorWhite;
        }
      }
    }
  }

  &.sm {
    min-width: pxToRem(122px);
    width: pxToRem(122px);

    .box__inner {
      height: pxToRem(30px);

      input {
        width: pxToRem(62px);
      }

      .btn {
        width: pxToRem(30px);
        min-width: pxToRem(30px);
      }
    }

    .pack-info {
      font-family: "Roboto Light";
      font-size: pxToRem(10px);
      font-weight: 400;
      line-height: pxToRem(15px);
      top: calc(100%);
    }
  }

  &.full {
    min-width: 100%;
    width: 100%;

    .box {
      &__inner {
        width: 100%;

        input {
          width: calc(100% - pxToRem(60px));
        }
      }
    }
  }

  .pack-info {
    position: absolute;
    left: 0;
    top: calc(100% + pxToRem(4px));
    width: 100%;
    display: flex;
    justify-content: center;
    opacity: 0;

    @include typo_12_light;
    text-transform: uppercase;
    transition: opacity 0.3s;
  }

  &.vertical {
    min-width: pxToRem(40px);
    width: pxToRem(40px);
    height: pxToRem(120px);

    button {
      border: 0;
    }

    .btn {
      border: 0;
      width: 100% !important;
      min-width: 100% !important;
      height: pxToRem(40px) !important;
      min-height: pxToRem(40px) !important;

      &.decrement {
        border-radius: 0 0 pxToRem(5px) 0;
      }

      &.increment {
        border-radius: 0 pxToRem(5px) 0 0;
      }

      svg {
        path {
          transition: 0s;
        }
      }
      &:hover {
        background: $colorBlack;
        color: $colorWhite;

        svg {
          path {
            fill: $colorWhite;
            stroke: $colorWhite;
          }
        }
      }
    }

    .box {
      &__inner {
        flex-direction: column-reverse;
        height: 100%;
      }
    }

    input {
      max-width: 100%;
      min-width: 100%;
      border: 0;
      border-top: pxToRem(1px) solid $colorLightGray;
      border-bottom: pxToRem(1px) solid $colorLightGray;
    }

    .pack-info {
      display: none;
    }
  }
}

@media (max-width: 1024px) {
  .box {
    &,
    *.sm {
      min-width: pxToRem(115px);
      width: pxToRem(115px);

      .box__inner {
        height: pxToRem(30px);
      }
    }

    input {
      width: pxToRem(55px);
      @include typo_14_regular;
      line-height: pxToRem(20px);
    }

    .btn {
      width: pxToRem(30px);
      min-width: pxToRem(30px);
    }

    .pack-info {
      top: calc(100% + pxToRem(1px));
      @include typo_10_light;
    }
  }
}
