@import "../../styles/functions";
@import "../../styles/variables";
@import "../../styles/mixins";

.box {
  display: flex;
  align-items: center;
  gap: pxToRem(18px);

  position: relative;
  z-index: 2;

  .title {
    @include typo_16_medium;
    position: relative;

    &.info {
      padding-right: pxToRem(20px);
    }

    .tooltip {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;

      svg {
        width: pxToRem(15px);
        height: pxToRem(15px);
      }
    }
  }

  .subtitle {
    @include typo_14_regular;
    line-height: pxToRem(20px);
  }

  .inputs {
    display: flex;
    align-items: center;
    gap: pxToRem(10px);

    > *:nth-child(1) {
      max-width: pxToRem(180px);
      width: 100%;
    }

    > *:nth-child(2) {
      max-width: pxToRem(90px);
      width: 100%;
    }
  }

  .search {
    position: relative;

    &__panel {
      position: absolute;
      top: calc(100% + pxToRem(10px));
      left: pxToRem(-10px);
      z-index: 100;
    }
  }

  .btn {
    min-width: pxToRem(153px);
    min-height: pxToRem(30px);

    > * {
      min-height: 100%;
    }
  }
}

.card,
.burger {
  width: 100%;
  background: $colorWhite;
  border-radius: pxToRem(5px);
  border: pxToRem(1px) solid $colorLightGray;
  height: 100%;
  padding: pxToRem(35px) pxToRem(35px) pxToRem(32px);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .title {
    @include typo_21_medium;
    margin-bottom: pxToRem(42px);
  }

  .inputs {
    width: 100%;
    gap: pxToRem(37px);
    margin-top: auto;

    > *:nth-child(1) {
      max-width: pxToRem(265px);
    }

    > *:nth-child(2) {
      max-width: pxToRem(130px);
    }
  }

  .btn {
    margin-top: auto;
    margin-left: auto;
    width: pxToRem(260px);
    margin-bottom: auto;
  }
}

.card {
  .title {
    @include typo_21_regular;
  }
}

.burger {
  border: 0;
  padding: 0;
  height: unset;

  .title {
    @include typo_16_medium;
    margin-bottom: pxToRem(0px);
  }

  .btn {
    margin-top: 0;
    margin-left: 0;
    width: 100%;
    max-width: pxToRem(435px);
  }
}

@media screen and (max-width: 1024px) {
  .card {
    padding: pxToRem(22px) pxToRem(15px) pxToRem(22px);
    gap: 0;

    .title {
      @include typo_18_medium;
      margin-top: pxToRem(5px);
      margin-bottom: pxToRem(15px);
    }

    .inputs {
      width: 100%;
      gap: pxToRem(10px);

      > *:nth-child(1) {
        max-width: calc(100% - pxToRem(110px));
      }

      > *:nth-child(2) {
        max-width: pxToRem(100px);
      }
    }

    .btn {
      margin-top: pxToRem(35px);
      margin-left: auto;
      width: 100%;

      button {
        padding: pxToRem(10px);
      }
    }

    input {
      &::placeholder {
        font-family: "Roboto Light" !important;
        font-size: pxToRem(16px) !important;
        font-style: normal !important;
        font-weight: 300 !important;
        line-height: pxToRem(21px) !important;
        letter-spacing: 0.02em !important;
      }
    }
  }
}
