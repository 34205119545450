@import "../../styles/functions";
@import "../../styles/mixins";
@import "../../styles/variables";

.box {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .text {
    margin-bottom: pxToRem(44px);
    max-width: pxToRem(435px);

    * {
      @include typo_21_light;
    }

    div {
      display: inline;
    }

    span {
      font-weight: 700;
      font-family: "Roboto Bold";
    }
  }

  .info {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: px(10px);

    > div {
      width: 100%;
      max-width: pxToRem(230px);

      display: flex;
      flex-direction: column;
      gap: pxToRem(5px);
    }

    &__col {
      div:nth-child(1) {
        @include typo_14_medium;
      }

      div:nth-child(2):not(.form__alert) {
        @include typo_18_light;
      }
    }
  }

  .form__alert {
    color: $colorRed;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: pxToRem(10px);
    margin-top: pxToRem(10px);
    position: absolute;
    opacity: 0;
    transition: 0.3s;

    &.visible {
      opacity: 1;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: pxToRem(10px);
    margin-top: auto;

    > * {
      max-width: pxToRem(230px);
      padding: pxToRem(10px) pxToRem(10px);
    }
  }

  &.error {
    .buttons {
      justify-content: flex-end;
    }

    .text {
      span {
        @include typo_21_light;
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: pxToRem(5px);
      max-height: pxToRem(150px);
      overflow-y: auto;
      margin-bottom: pxToRem(20px);
    }
  }
}

@media (max-width: 1024px) {
  .box {
    .text {
      margin-bottom: pxToRem(30px);
      max-width: calc(100% - pxToRem(45px));

      * {
        @include typo_16_light;
      }
    }

    .info {
      flex-direction: column;
      gap: pxToRem(25px);

      > div {
        width: 100%;
        max-width: 100%;

        display: flex;
        flex-direction: column;
        gap: pxToRem(5px);
      }

      &__col {
        max-width: 100%;

        div:nth-child(2):not(.form__alert) {
          @include typo_18_light;
        }

        .form__alert {
          @include typo_14_light;
        }
      }

      .form__alert {
        margin-top: 0;
      }
    }

    .buttons {
      > * {
        max-width: 100%;
      }
    }
  }
}
