@import "../../styles/functions";
@import "../../styles/variables";
@import "../../styles/mixins";

.box {
  display: flex;
  flex-direction: column;
  margin: auto;

  padding-bottom: pxToRem(71px);

  .breadcrumbs {
    padding-bottom: pxToRem(0px);
  }

  .content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: pxToRem(13px);
    gap: pxToRem(10px);

    > *:nth-child(1) {
      width: 22%;
    }

    > *:nth-child(2) {
      width: 75%;
      max-width: pxToRem(1156px);
    }

    .title {
      @include typo_50_thin;
      margin-bottom: pxToRem(40px);
      margin-top: pxToRem(13px);
    }

    .filters {
      position: sticky;
      top: pxToRem(100px);
      z-index: 2;
    }
  }
}

@media (max-width: 1024px) {
  .box {
    padding-bottom: pxToRem(50px);

    .breadcrumbs {
      padding-bottom: pxToRem(0px);
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: pxToRem(30px);

      > *:nth-child(1) {
        width: 100%;
      }

      > *:nth-child(2) {
        width: 100%;
        max-width: 100%;
      }

      .title {
        @include typo_28_thin;
        margin-bottom: pxToRem(25px);
        margin-top: pxToRem(35px);
      }

      .filters {
        position: unset;
        top: unset;
      }
    }
  }
}
