@import "../../styles/functions";
@import "../../styles/variables.scss";
@import "../../styles/mixins";

.box {
  height: pxToRem(22px);
  width: pxToRem(30px);

  &__wrap {
    height: pxToRem(22px);
    width: pxToRem(30px);
    cursor: pointer;
  }

  &.open {
    .burgx,
    .burgx2,
    .burgx3 {
      background: $colorBlack;
    }

    .burgx {
      -webkit-transform: translateY(pxToRem(7px)) translateX(pxToRem(1px)) rotate(-135deg);
      transform: translateY(pxToRem(7px)) rotate(-135deg);
      -moz-transform: translateY(pxToRem(7px)) rotate(-135deg);
      -o-transform: translateY(pxToRem(7px)) rotate(-135deg);
    }

    .burgx2 {
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
      -moz-transform: translateX(100%);
      -o-transform: translateX(100%);
      opacity: 0;
    }

    .burgx3 {
      -webkit-transform: translateY(pxToRem(-13px)) rotate(135deg);
      transform: translateY(pxToRem(-13px)) rotate(135deg);
      -moz-transform: translateY(pxToRem(-13px)) rotate(135deg);
      -o-transform: translateY(pxToRem(-13px)) rotate(135deg);
    }
  }

  .burgx,
  .burgx2,
  .burgx3 {
    position: relative;
    background: $colorBlack;
    margin-bottom: pxToRem(8px);
    border-radius: $borderRadiusBig;

    &:not(:last-child) {
      // margin-bottom: pxToRem(5px);
    }

    width: pxToRem(30px);
    height: pxToRem(2px);
    transition: all 0.4s;
    opacity: 1;
    transform: translateY(0px) rotate(0deg);
  }
}

@media (max-width: 1024px) {
  .box {
  }
}
