@import "../../styles/functions";
@import "../../styles/variables";
@import "../../styles/mixins";

.box {
  display: flex;
  margin: auto;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: pxToRem(10px);

    > *:first-child {
      width: 100%;
    }

    .toolbar {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;

      &__col {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(var(--grid-template-cols, 3), 1fr);
        column-gap: pxToRem(20px);
      }

      &__sort {
        width: 100%;
        height: pxToRem(60px);
        position: relative;
        display: block;

        &:first-child {
          z-index: 2;
        }

        &:last-child {
          z-index: 1;
        }

        > * {
          position: absolute;
          z-index: 100;
          &:first-child {
            max-width: unset;
          }
        }
      }
    }
  }

  .empty-message {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: pxToRem(50px) 0;
    width: 100%;
    @include typo_28_thin;
    text-align: center;
  }

  .view-btn {
    position: absolute;
    right: 0;
    cursor: pointer;
    width: pxToRem(40px);
    height: pxToRem(40px);
    border-radius: 50%;
    border: pxToRem(1px) solid $colorLightGray;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;

    svg rect {
      transition: 0.3s;
    }

    &:hover {
      border: pxToRem(1px) solid $colorBlack;
      background: $colorBlack;

      svg {
        rect {
          fill: $colorWhite;
          stroke: $colorWhite;
        }
      }
    }
  }

  .title {
    @include typo_50_thin;
    color: $colorBlack;
    padding-top: pxToRem(40px);
  }

  .description {
    @include typo_18_light;
    color: $colorBlack;
    padding-top: pxToRem(20px);
    padding-bottom: pxToRem(60px);
    max-width: pxToRem(615px);
  }

  .filters {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .products {
    display: flex;
    flex-wrap: wrap;
    padding: pxToRem(25px) 0 pxToRem(80px) 0;
    min-height: pxToRem(608px);
  }

  .spinner {
    width: 100%;
    padding: pxToRem(30px) 0;
    position: absolute;
    left: 0;
    top: calc(50% - pxToRem(70px));
  }

  .accordion-regular {
    * {
      font-family: "Roboto Regular";
      font-weight: 400;
    }
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .box {
    .products {
      flex-direction: column;
    }
  }
}

@media (max-width: 768px) {
  .box {
    .breadcrumbs {
      padding-bottom: pxToRem(55px);
    }

    .toolbar {
      width: 100%;
      max-width: 100%;
      display: unset;

      &__col {
        display: flex;
        gap: pxToRem(10px);
        flex-direction: column;
      }

      &__sort {
        width: 100%;
        height: pxToRem(40px);
      }
    }

    .view-btn {
      display: none;
    }

    .title {
      @include typo_28_thin;
      padding-top: 0;
    }

    .description {
      @include typo_16_light;
      padding-bottom: pxToRem(40px);
    }

    .products {
      flex-direction: column;
      padding: pxToRem(15px) 0 pxToRem(60px) 0;
    }
  }
}
