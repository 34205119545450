@import "../../../../styles/functions";
@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.box {
  border-radius: pxToRem(5px);
  border: pxToRem(1px) solid $colorLightGray;
  height: pxToRem(65px);

  &:not(.visible) {
    .box {
      &__inner {
        > *:not(:last-child) {
          opacity: 0.5;
        }
      }
    }
  }

  &:not(.interactive) {
    .name {
      max-width: pxToRem(487px);
    }
    .count,
    .counter {
      max-width: pxToRem(80px);
    }
    .summ {
    }
    .total {
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
  }

  &__group {
    width: calc(100% - pxToRem(80px));
    display: flex;
    align-items: center;
  }

  .image {
    padding: pxToRem(1px) pxToRem(1px);
    border-right: pxToRem(1px) solid $colorLightGray;
    width: pxToRem(100px);
    height: pxToRem(65px);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .name {
    @include typo_18_regular;
    line-height: pxToRem(24px);
    width: 100%;
    max-width: pxToRem(418px);
    margin-right: pxToRem(20px);

    letter-spacing: 0.02em;

    &__inner {
      @include text_overflow_elipses(1);
      white-space: normal;
    }

    a {
      @include hover_underline;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .count,
  .counter {
    @include typo_16_regular;
    width: 100%;
    max-width: pxToRem(122px);
    margin-right: pxToRem(20px);
  }

  .number {
    @include typo_16_regular;
    width: 100%;
    max-width: pxToRem(120px);
    margin-right: pxToRem(20px);
    margin-left: pxToRem(18px);
  }

  .summ {
    @include typo_18_regular;
    text-align: right;
    width: 100%;
    max-width: pxToRem(110px);
    margin-right: pxToRem(20px);
  }

  .total {
    @include typo_18_regular;
    text-align: right;
    width: 100%;
    max-width: pxToRem(120px);
  }

  .delete {
    width: pxToRem(20px);
    height: pxToRem(20px);

    position: absolute;
    right: pxToRem(23px);
    top: calc(50% - pxToRem(9px));

    &:hover {
      svg {
        circle {
          fill: $colorBlack;
        }
        path {
          stroke: $colorWhite;
        }
      }
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
