@import "../../../styles/functions";
@import "../../../styles/mixins";
@import "../../../styles/variables";

.box {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  padding-top: pxToRem(35px);

  .list {
    max-height: 75%;
    overflow-y: auto;
    &__item {
      padding: pxToRem(19px) 0;
      border-top: pxToRem(1px) solid $colorLightGray;

      &:last-child {
        border-bottom: pxToRem(1px) solid $colorLightGray;
      }
    }
  }

  .button {
    max-width: pxToRem(230px);
    width: 100%;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: pxToRem(27px);
  }

  .text {
    &__title {
      @include typo_18_regular;
      margin-bottom: pxToRem(20px);
      letter-spacing: 0.01em;
    }

    &__desc {
      @include typo_16_light;
      letter-spacing: 0.01em;
      max-width: 80%;
      display: flex;
      flex-direction: column;
    }
  }
}

@media (max-width: 1024px) {
  .box {
    padding-top: pxToRem(30px);
    overflow-y: auto;
    // max-height: calc(100% - pxToRem(42px));

    .button {
      max-width: 100%;
      width: 100%;
      margin-bottom: 0;
      margin-top: pxToRem(40px);
    }

    .text {
      &__title {
        @include typo_18_regular;
        margin-bottom: pxToRem(20px);
        letter-spacing: 0.01em;
      }

      &__desc {
        @include typo_16_light;
        letter-spacing: 0.01em;
        max-width: 80%;
      }
    }
  }
}
