@import "../../styles/functions";
@import "../../styles/variables.scss";
@import "../../styles/mixins";

.container {
  z-index: 1001;
}

.box {
  min-width: pxToRem(45px);
  min-height: pxToRem(45px);
  width: pxToRem(45px);
  height: pxToRem(45px);
  background: transparent;
  border-radius: 50%;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.active {
    background: #fdfbf9;
  }

  &:not(.favorite):hover {
    background: #fdfbf9;
  }
}

.panel {
  width: pxToRem(160px);
  padding-top: pxToRem(10px);
  position: absolute;
  right: pxToRem(15px);

  z-index: 1000;

  &__inner {
    display: flex;
    flex-direction: column;
    background: $colorMilky;
    box-shadow: 0px 0px 25px 0px #00000026;
    padding: pxToRem(5px) pxToRem(25px);
    border-radius: pxToRem(5px);
    border: pxToRem(1px) solid $colorWhite;

    > * {
      @include typo_16_medium;
      margin: pxToRem(5px) 0;
      // min-height: pxToRem(40px);
      display: inline-block;
      align-items: center;
      width: max-content;
      border-bottom: pxToRem(1px) solid transparent;

      &:hover {
        border-bottom: pxToRem(1px) solid $colorBlack;
      }
    }
  }
}

@media (max-width: 1024px) {
  .box {
  }
}
