@import "../../styles/functions";
@import "../../styles/variables";
@import "../../styles/mixins";

.box {
  background: $colorMilky;
  min-height: pxToRem(80px);
  border-bottom: pxToRem(1px) solid var(--header-border-bottom, transparent);

  &.fixed {
    .box {
      &__inner {
        position: fixed;
        z-index: 1000;
        background: $colorMilky;
        min-height: pxToRem(80px);
        border-bottom: pxToRem(1px) solid var(--header-border-bottom, transparent);
        width: 100%;
        max-width: 100%;
        padding-left: calc((100% - pxToRem(1548px)) / 2);
        padding-right: calc((100% - pxToRem(1548px)) / 2);
        top: -100vh;
        transition: top 0.6s;
      }
    }

    &.visible {
      .box {
        &__inner {
          top: 0;
        }
      }
    }

    &.fixed-disable-transition {
      .box {
        &__inner {
          transition: 0s !important;
          top: 0 !important;
        }
      }
    }
  }

  &__inner {
    padding: pxToRem(15px) 0 pxToRem(11px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: pxToRem(30px);
  }

  .logo {
    max-width: pxToRem(136px);
    width: 100%;

    img {
      width: 100%;
    }
  }

  .toolbar {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: pxToRem(20px);
    max-width: pxToRem(775px);
    width: 100%;
    margin-bottom: pxToRem(6px);
    margin-right: pxToRem(-10px);

    &,
    &-mobile {
      > a {
        min-width: pxToRem(45px);
        min-height: pxToRem(45px);
        width: pxToRem(45px);
        height: pxToRem(45px);
        background: transparent;
        border-radius: 50%;
        transition: 0.3s;
        display: flex;
        justify-content: center;
        align-items: center;

        &.active {
          background: #fdfbf9;
        }

        &:hover {
          background: #fdfbf9;
        }
      }
    }

    &-mobile {
      display: flex;
      align-items: center;
      gap: pxToRem(15px);
      margin-left: auto;

      a {
        display: flex;
        align-items: center;

        &.active {
          background: #fdfbf9;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .box {
    position: fixed;
    z-index: 10000;
    width: 100%;

    &__inner {
      padding: pxToRem(20px) 0 pxToRem(10px);

      .logo {
        max-width: pxToRem(90px);
      }

      .toolbar {
        gap: pxToRem(5px);
      }
    }
  }
}

@media (max-width: 767px) {
  .box {
    min-height: pxToRem(67px);
    position: fixed;
    z-index: 10000;
    width: 100%;
    height: pxToRem(67px);

    &__inner {
      padding: pxToRem(10px) 0 pxToRem(10px);
      min-height: pxToRem(67px);
      gap: pxToRem(20px);
      align-items: center;

      .logo {
        max-width: pxToRem(90px);
        display: flex;
        align-items: center;
      }
    }
  }
}
