@import "../../styles/functions";
@import "../../styles/variables";
@import "../../styles/mixins";

.box {
  padding: pxToRem(100px) 0;
  &__inner {
  }
}

@media (max-width: 1024px) {
  .box {
    &__inner {
    }
  }
}
