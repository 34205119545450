@import "./variables.scss";
@import "./mixins.scss";

html {
  --scroll-behavior: smooth !important;
  scroll-behavior: smooth !important;
}

:root {
  font-size: 0.926vw !important;

  @media (max-width: 1024px) {
    font-size: 16px !important;
  }

  @media (max-width: 767px) {
    // font-size: 3vw !important;
    // font-size: 16px !important;
  }

  @media (max-width: 320px) {
    font-size: 16px !important;
  }
}

body {
  padding: 0;
  margin: 0;
  font-family: "Roboto Regular", sans-serif;

  * {
    box-sizing: border-box;
  }
}

code {
  font-family: "Courier New", monospace;
}

div#root {
  display: flex;
  flex-direction: column;
}

main {
  min-height: pxToRem(200px);

  @media (max-width: 1024px) {
    padding-top: pxToRem(81px);
  }

  @media (max-width: 767px) {
    padding-top: pxToRem(67px);
  }
}

body,
input,
textarea {
  /* width */
  ::-webkit-scrollbar {
    width: pxToRem(3px);
    height: pxToRem(2px);
  }

  /* Track */
  ::-webkit-scrollbar-track {
    // background: #fff;
    border-radius: pxToRem(50px);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #333333;
    border-radius: pxToRem(5px);
    overflow: hidden;
    width: pxToRem(3px);
  }

  ::-webkit-scrollbar {
    width: pxToRem(3px);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    width: pxToRem(2px);
  }

  /* Rectangle 435 */
}

button {
  outline: none;
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: $colorBlack;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  color: $colorBlack;
  text-decoration: unset;
  &:hover {
    // color: #0dff51;
  }
}

ul,
ol {
  margin: 0;
}

svg {
  path {
    transition: 0.3s;
  }
}

.tooltip-content {
  background: $colorWhite !important;
  color: $colorBlack !important;
  border: pxToRem(1px) solid $colorBlack !important;
  padding: pxToRem(10px) !important;
  border-radius: pxToRem(5px) !important;
  @include typo_12_regular;
  font-size: pxToRem(12px) !important;
  z-index: 100;

  --rt-opacity: 1;

  [class*="react-tooltip-arrow"] {
    background: $colorWhite !important;
    border-bottom: pxToRem(1px) solid $colorBlack !important;
    border-right: pxToRem(1px) solid $colorBlack !important;
    width: pxToRem(7px) !important;
    height: pxToRem(7px) !important;
    left: pxToRem(11px) !important;
  }

  &[class*="react-tooltip__place-top"] {
    [class*="react-tooltip-arrow"] {
      bottom: pxToRem(-4px) !important;
    }
  }

  &[class*="react-tooltip__place-bottom-start"] {
    [class*="react-tooltip-arrow"] {
      top: pxToRem(-4px) !important;
    }
  }
}

@keyframes typing {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, pxToRem(20px));
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes typing-big {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, pxToRem(40px));
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 1;
    transform: translate(-50%, -75%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.Toastify {
  .Toastify__toast-body {
    color: $colorBlack;
    @include typo_16_regular;
    line-height: pxToRem(20px);
  }

  &__toast-container {
    z-index: 999999;
    width: pxToRem(320px);
    padding: pxToRem(4px);

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .Toastify__toast {
    padding: pxToRem(4px);
    max-height: pxToRem(800px);
    min-height: pxToRem(64px);
    box-shadow: 0px pxToRem(4px) pxToRem(12px) rgba(0, 0, 0, 0.1);
  }

  .Toastify__toast-icon {
    margin-inline-end: pxToRem(10px);
    width: pxToRem(20px);
  }

  .Toastify__close-button {
    height: pxToRem(20px);
    width: pxToRem(14px);

    svg {
      height: pxToRem(16px);
      width: pxToRem(14px);
    }
  }
}
