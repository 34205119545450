@import "../../../styles/functions";
@import "../../../styles/variables.scss";
@import "../../../styles/mixins";

.box {
  width: 100%;

  padding: pxToRem(95px) 0 pxToRem(72px);
  background: var(--product-slider-bg, "transparent");

  .header {
    position: relative;
  }

  .title {
    @include typo_50_thin;
    margin-bottom: pxToRem(39px);
    color: $colorBlack;
  }

  .slider {
    max-width: pxToRem(1920px);
    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: relative;

    &:hover {
      :global(.swiper-button-disabled) {
        opacity: 0.5;
      }
    }
  }
  :global(.swiper-wrapper) {
    // transform: translate3d(pxToRem(90px), 0px, 0px) !important;
  }

  :global(.swiper) {
    width: 100%;
    height: 100%;
    border-radius: pxToRem(5px);
    overflow: hidden;
    padding: pxToRem(18px) pxToRem(90px);
    :global(.swiper-wrapper) {
      height: 100%;
    }

    :global(.swiper-slide) {
      height: auto;
      border-radius: pxToRem(5px);
      overflow: unset !important;
      margin-right: 0 !important;
      width: auto !important;
      &:not(:last-child) {
        margin-right: pxToRem(20px) !important;
      }
    }
  }

  :global(.swiper-button-disabled) {
    border: pxToRem(1px) solid rgba(170, 170, 170, 1) !important;
    svg {
      path {
        stroke: rgba(170, 170, 170, 1);
      }
    }

    &:hover {
      background: transparent !important;
      svg {
        path {
          stroke: rgba(170, 170, 170, 1) !important;
        }
      }
    }
  }

  :global(.swiper-button-lock) {
    display: none !important;
  }

  &.no-swipe {
  }

  &.hide-controls {
    .button_prev,
    .button_next {
      display: none;
    }
  }

  .button_prev,
  .button_next {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: pxToRem(40px);
    height: pxToRem(40px);
    border-radius: 50%;
    z-index: 10;
    top: calc(50% - pxToRem(20px));
    border: pxToRem(1px) solid #333333;
    transition: 0.3s;
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: $colorBlack;
        svg {
          path {
            stroke: $colorWhite;
          }
        }
      }
    }

    svg {
      width: pxToRem(14px);
    }
  }

  .button_prev {
    right: pxToRem(55px);
  }

  .button_next {
    right: 0;
    transform: rotate(-180deg);
  }

  .empty-message {
    padding: pxToRem(20px) 0;
    @include typo_28_thin;
  }
}

// @media (min-width: 1025px) {
//   .box {
//     :global(.swiper) {
//       :global(.swiper-slide) {
//         width: auto !important;
//       }
//     }
//   }
// }

@media (max-width: 1024px) {
  .box {
    padding: pxToRem(40px) 0 pxToRem(75px);
    position: relative;

    .header {
      position: unset;
    }

    .title {
      @include typo_28_thin;
      margin-bottom: pxToRem(12px);
    }

    :global(.swiper) {
      padding: pxToRem(15px) pxToRem(30px);
      :global(.swiper-slide) {
        &:not(:last-child) {
          margin-right: pxToRem(10px) !important;
        }
      }
    }

    .button_prev,
    .button_next {
      top: unset;
      left: var(--left-position, pxToRem(30px));
      bottom: pxToRem(27px);

      &:active {
        background: $colorBlack;
        svg {
          path {
            stroke: $colorWhite;
          }
        }
      }
    }

    .button_prev {
      right: unset;
    }

    .button_next {
      left: calc(pxToRem(40px) + var(--left-position, pxToRem(30px)) + pxToRem(15px));
    }

    &.nav-right {
      .button_prev {
        left: unset;
        right: calc(pxToRem(40px) + var(--left-position, pxToRem(30px)) + pxToRem(15px));
      }

      .button_next {
        right: var(--left-position, pxToRem(30px));
        left: unset;
      }
    }
  }
}

@media (max-width: 767px) {
  .box {
    :global(.swiper) {
      padding: pxToRem(15px) pxToRem(20px);
    }

    .button_prev,
    .button_next {
      left: var(--left-position, pxToRem(20px));
    }

    .button_next {
      left: calc(pxToRem(40px) + var(--left-position, pxToRem(20px)) + pxToRem(15px));
    }
  }
}
