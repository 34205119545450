@import "../../styles/functions";
@import "../../styles/variables.scss";
@import "../../styles/mixins";

.dots {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 49%;
  padding: pxToRem(20px) 0 pxToRem(20px);

  .dot {
    display: inline-block;
    background-color: $colorLike;
    border-radius: 50%;
    margin-right: pxToRem(15px);
    &.md {
      width: pxToRem(20px);
      height: pxToRem(20px);
      animation: typing-big 1s linear infinite;
    }
    &.sm {
      width: pxToRem(10px);
      height: pxToRem(10px);
      animation: typing 1s linear infinite;
    }
  }
}

@keyframes typing {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, pxToRem(20px));
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes typing-big {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, pxToRem(40px));
  }
  100% {
    transform: translate(0, 0);
  }
}
