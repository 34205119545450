@import "../../../styles/functions";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.box {
  padding: pxToRem(13px) pxToRem(20px) pxToRem(13px) pxToRem(35px);
  border-radius: pxToRem(5px);
  border: pxToRem(1px) solid $colorLightGray;

  &__inner {
    display: flex;
    align-items: center;
    gap: pxToRem(30px);
  }

  .number {
    @include typo_16_regular;
    text-transform: uppercase;
    width: 100%;
    max-width: pxToRem(120px);
    margin-right: pxToRem(30px);
  }

  .summ {
    @include typo_16_regular;
    width: 100%;
    max-width: pxToRem(120px);
  }

  .date {
    width: 100%;
    max-width: pxToRem(140px);
    @include typo_16_regular;
  }

  .user {
    width: 100%;
    max-width: pxToRem(200px);

    .import__txt {
      @include typo_10_regular;
      font-size: pxToRem(8px);
      line-height: pxToRem(9px);
      letter-spacing: 0.02em;
      text-align: center;
    }

    div {
      display: flex;
      align-items: center;
      gap: pxToRem(0px);
      @include typo_16_regular;
    }

    svg {
      transform: translateX(pxToRem(-6px));
    }
  }

  .tools {
    width: 100%;
    max-width: pxToRem(330px);
    margin-left: auto;
    display: flex;
    align-items: center;

    .download {
      margin-right: pxToRem(40px);
      display: flex;
      align-items: center;
      &:hover {
        svg {
          path {
            stroke: $colorGray;
          }
        }
      }
    }

    .detail {
      margin-right: pxToRem(25px);
      display: flex;
      align-items: center;

      &:hover {
        svg {
          path {
            stroke: $colorGray;
          }
        }
      }
    }

    .tooltip {
      max-width: pxToRem(150px);
      margin-left: pxToRem(-10px);
      @include typo_12_regular;

      z-index: 1;
      * {
        letter-spacing: 0.01em;
      }
    }
  }
}

.mobile {
  padding: 0;
  border-radius: pxToRem(5px);
  border: pxToRem(1px) solid $colorMilky;

  .box {
    &__inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: pxToRem(0px);
    }
  }

  .summ {
    @include typo_26_light;
    width: 100%;
    max-width: pxToRem(120px);
  }

  .date {
    width: 100%;
    max-width: pxToRem(140px);
    @include typo_26_light;
  }

  .tools {
    width: 100%;
    max-width: pxToRem(330px);
    margin-left: auto;
    display: flex;
    align-items: center;

    .download {
      margin-right: pxToRem(40px);
      display: flex;
      align-items: center;
      &:hover {
        svg {
          path {
            stroke: $colorGray;
          }
        }
      }
    }

    .detail {
      margin-right: pxToRem(25px);
      display: flex;
      align-items: center;

      &:hover {
        svg {
          path {
            stroke: $colorGray;
          }
        }
      }
    }

    .tooltip {
      max-width: pxToRem(150px);
      margin-left: pxToRem(-10px);
      z-index: 1;
      height: unset;
      @include typo_12_regular;

      * {
        letter-spacing: 0.01em;
      }
    }
  }

  .number {
    @include typo_14_medium;
    height: 100%;
    margin-right: pxToRem(0px);
  }

  .download {
    line-height: pxToRem(20px) !important;
    height: pxToRem(20px) !important;
  }

  .detail {
    display: flex;
    align-items: center;
  }

  .row {
    display: flex;
    align-items: center;
    padding: pxToRem(8px) pxToRem(10px);
    width: 100%;

    &:nth-child(1) {
      background: $colorMilky;
    }

    &:nth-child(2) {
      border-bottom: pxToRem(1px) solid $colorLightGray;
    }

    &:nth-child(3) {
      padding-top: pxToRem(15px);
      padding-bottom: pxToRem(20px);
    }

    .col {
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      * {
        @include typo_14_medium;
        height: 100%;
        margin-right: pxToRem(0px);
        text-align: center;
      }

      [class*="react-tooltip"] {
        height: unset;
      }

      &::after {
        content: "";
        width: pxToRem(1px);
        height: calc(100% + 16px);
        right: 0;
        top: pxToRem(-8px);
        position: absolute;
        background: $colorLightGray;
      }

      &__number {
        max-width: 36%;
        width: 100%;
      }

      &__summ {
        max-width: 30.7%;
        width: 100%;
      }

      &__date {
        max-width: 32.8%;
        width: 100%;

        &::after {
          display: none;
        }
      }

      &__user {
        max-width: 80%;
        width: 100%;

        &::after {
          display: none;
        }
      }

      &__link {
        max-width: 20%;
        width: 100%;
        &::after {
          display: none;
        }
      }

      &__button {
        max-width: pxToRem(194px);
        margin-right: auto;
        width: 100%;

        * {
          @include typo_10_medium;
        }

        &::after {
          display: none;
        }
      }
      &__detail {
        max-width: 20%;
        width: 100%;
        &::after {
          display: none;
        }
      }

      .user {
        display: flex;
        align-items: center;
        @include typo_14_regular;
        line-height: pxToRem(15px);
        text-align: left;
        width: 100%;
        max-width: 100%;
        padding-left: pxToRem(8px);
      }
    }
  }
}
