@import "../../styles/functions";
@import "../../styles/variables";
@import "../../styles/mixins";

.box {
  display: flex;
  flex-direction: column;
  margin: auto;

  &__inner {
    padding: 0px 0 pxToRem(90px);
  }

  .row {
    &:not(:last-child) {
      margin-bottom: pxToRem(90px);
    }

    &.highlights {
      margin-bottom: pxToRem(68px);
    }

    .box__inner {
      padding: 0;
    }
  }

  .quicklinks {
    margin-bottom: pxToRem(80px);
  }

  .btn {
    width: 100%;
    max-width: pxToRem(160px);
  }

  .tags {
    display: flex;
    align-items: center;
    gap: pxToRem(15px);
    // margin-bottom: pxToRem(45px);
  }

  .list {
    display: flex;
    gap: pxToRem(20px);
  }

  .title {
    @include typo_50_thin;
    margin-bottom: pxToRem(32px);
    color: $colorBlack;
  }

  .category {
    width: calc(100% - 8rem);
    overflow: auto;

    @media (max-width: 1022px) {
      width: 100%;
    }

    .tags {
      margin-bottom: pxToRem(3px);
    }

    &__slider {
      // margin-top: pxToRem(-84px);
    }
  }
}

@media (max-width: 1024px) {
  .box {
    &__inner {
      padding: 0px 0 pxToRem(60px);
      max-width: 100%;
    }

    .row {
      position: relative;

      &:not(:last-child) {
        margin-bottom: pxToRem(60px);
      }
    }

    .quicklinks {
      margin-bottom: pxToRem(60px);
    }

    .btn {
      position: absolute;
      z-index: 1;
      width: unset;
      min-width: pxToRem(160px);
      max-width: unset;
      display: inline-flex;
      bottom: 0;
    }

    .tags {
      gap: pxToRem(8px);
      margin-bottom: pxToRem(10px) !important;
      width: 100%;
      overflow-y: auto;
      padding: 0 pxToRem(30px) 0;

      @include clear_scrollbar_styles;
    }

    .list {
      display: flex;
      gap: pxToRem(20px);
    }

    .title {
      padding: 0 pxToRem(30px);
      @include typo_28_thin;
      margin-bottom: pxToRem(22px);
    }
  }
}

@media (max-width: 767px) {
  .box {
    .row {
      &:not(:last-child) {
        margin-bottom: pxToRem(50px);
      }
    }

    .quicklinks {
      margin-bottom: pxToRem(50px);
    }

    .title {
      padding: 0 pxToRem(20px);
    }

    .tags {
      padding: 0 pxToRem(20px) 0;
    }
  }
}
