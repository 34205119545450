@import "../../../styles/functions";
@import "../../../styles/variables.scss";
@import "../../../styles/mixins";

.box {
  // width: 100%;
  width: pxToRem(372px);
  height: pxToRem(326px);
  background: $colorMilky;
  border: pxToRem(1px) solid transparent;
  border-radius: pxToRem(5px);
  overflow: hidden;
  transition: 0.3s;
  padding: pxToRem(41px) pxToRem(40px) pxToRem(33px) pxToRem(45px);

  color: $colorBlack;

  &.lg {
    width: pxToRem(764px);
  }

  &:hover {
    box-shadow: 0px 0px pxToRem(25px) 0px #00000026;
    border: pxToRem(1px) solid $colorWhite;

    .link {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    height: 100%;
  }

  .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .title {
    @include typo_42_thin;
  }

  .link {
    display: inline-block;
    margin-top: pxToRem(12px);
    @include typo_16_regular;
    transition: 0.3s;
    opacity: 0;
    pointer-events: none;
    border-bottom: pxToRem(1px) solid transparent;

    @include hover_underline(-3px);
  }
}

@media (max-width: 1024px) {
  .box {
    width: pxToRem(317px);
    height: pxToRem(227px);
    padding: pxToRem(20px) pxToRem(19px) pxToRem(46px);

    color: $colorBlack;

    &.lg {
      width: pxToRem(380px);
    }

    .icon {
      position: absolute;
      right: 0;
      top: 0;
    }

    .title {
      @include typo_24_thin;
    }

    .link {
      display: inline-block;
      margin-top: pxToRem(0px);
      @include typo_14_regular;
      opacity: 1;
      pointer-events: all;
    }

    .icon {
      position: absolute;
      right: 0;
      top: 0;

      svg {
        width: 100%;
        height: 100%;
        max-width: pxToRem(40px);
        max-height: pxToRem(50px);
      }
    }
  }
}

@media (max-width: 767px) {
  .box {
    width: pxToRem(280px);
    height: pxToRem(190px);

    &.lg {
      width: pxToRem(280px);
    }
  }
}
