@import "../../styles/functions";

.container {
  max-width: pxToRem(1548px);
  width: 100%;
  margin: 0 auto;

  @media (min-width: 1440px) and (max-width: 1680px) {
    // max-width: calc(100% - pxToRem(132px));
  }

  @media (min-width: 1280px) and (max-width: 1439px) {
    // max-width: calc(100% - pxToRem(80px));
  }

  @media (max-width: 1279px) {
    // max-width: calc(100% - pxToRem(48px));
    // width: 100%;
  }

  @media (max-width: 1024px) {
    max-width: calc(100% - pxToRem(60px));
    // width: 100%;
  }

  @media (max-width: 767px) {
    max-width: calc(100% - pxToRem(36px));
  }
}
