@import "../../styles/functions";
@import "../../styles/variables.scss";
@import "../../styles/mixins";

.box {
  padding: pxToRem(30px) 0 pxToRem(22px);

  a:last-child span:last-child {
    font-family: "Roboto Medium";
    font-weight: 500;
  }

  a {
    @include typo_12_light;
    color: $colorBlack;
    transition: 0.3s;

    span {
      display: inline-block;
      border-bottom: 1px solid transparent;
    }

    &:not(:last-child) {
      margin-right: pxToRem(6px);

      &:after {
        content: "/";
        margin-left: pxToRem(5px);
        @include typo_12_regular;
        color: $colorBlack !important;
      }
    }

    span {
      @include hover_underline;
    }

    &.active {
      @include typo_12_medium;
      pointer-events: none;
    }

    position: relative;
  }
}

@media (max-width: 1024px) {
  .box {
    padding: pxToRem(10px) 0 pxToRem(18px);
  }
}
