@import "../../styles/functions";
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.modal {
  height: 100%;
  position: relative;

  &__content {
    height: calc(100% - pxToRem(32px));

    &.full {
      height: 100%;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;

    color: $colorBlack;

    @include typo_36_thin;

    &-btn {
      border: none;
      outline: none;
      background: transparent;
      margin-left: auto;
      transition: fill 0.3s;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 100;

      &:hover {
        svg {
          circle {
            fill: $colorBlack;
          }
          path {
            stroke: $colorWhite;
          }
        }
      }
    }
  }

  &__text {
    font-size: pxToRem(14px);
    font-weight: 400;
    line-height: pxToRem(16px);
  }

  &__btns {
    display: grid;
    grid-template-columns: repeat(var(--grid-size, 1), 1fr);
    column-gap: pxToRem(30px);
    margin-top: pxToRem(30px);
  }
}

@media (max-width: 1024px) {
  .modal {
    &__title {
      @include typo_28_thin;
      max-width: calc(100% - pxToRem(40px));
    }
  }
}
