@import "../../styles/functions";
@import "../../styles/variables.scss";
@import "../../styles/mixins";

.box {
  background: transparent;
  border-radius: pxToRem(50px);
  padding: pxToRem(10px) pxToRem(25px) pxToRem(9px);
  color: $colorBlack;
  transition: 0.3s;
  border: pxToRem(1px) solid $colorLightGray;
  white-space: nowrap;
  @include typo_18_regular;
  text-transform: uppercase;

  &:hover {
    border: pxToRem(1px) solid $colorDarkBlue;

    &.active {
      background: $colorDarkBlue;
      color: $colorWhite;
      border: pxToRem(1px) solid transparent;
    }
  }

  &.active {
    background: $colorDarkBlue;
    color: $colorWhite;
    border: pxToRem(1px) solid transparent;
  }
}

@media (max-width: 1024px) {
  .box {
    @include typo_16_regular;
    line-height: pxToRem(21px);
  }
}
