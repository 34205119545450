@import "../../../styles/functions";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.box {
  margin: 0 auto;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: pxToRem(12px);

  &.grid {
    display: grid;
    grid-template-columns: repeat(var(--grid-template-cols, 3), 1fr);
    gap: pxToRem(19px) pxToRem(20px);
  }

  &__item {
    &.full {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
}

@media (max-width: 1385px) {
  .box {
    &.grid {
      width: 100%;
      gap: pxToRem(12px);
    }

    &__item {
      > * {
        max-width: 100%;
      }
    }
  }
}
