@import "../../styles/functions";
@import "../../styles/variables.scss";
@import "../../styles/mixins";

.box {
  width: 100%;

  &__inner {
    width: 100%;

    &.error {
      input {
        border-bottom: pxToRem(1px) solid $colorRed;
        color: $colorRed;

        &::placeholder {
          color: $colorRed;
        }
      }
    }
  }

  .label {
    margin-bottom: pxToRem(5px);

    &.required {
    }

    &::after {
      content: "*";
    }
  }

  &.sm {
    input {
      height: pxToRem(30px);
    }
  }

  &.with-label {
    input {
      height: pxToRem(28px);
    }
  }

  input {
    border: 0;
    border-bottom: pxToRem(1px) solid $colorBlack;
    outline: none;
    background: transparent;
    height: pxToRem(40px);
    border-radius: 0;
    transition: 0.3s;
    display: flex;
    align-items: center;

    max-width: pxToRem(579px);
    width: 100%;
    @include typo_16_light;
    color: $colorBlack;

    &::placeholder {
      @include typo_18_light;
      letter-spacing: 0;
      color: $colorBlack;
    }

    &:active,
    &:focus,
    &:hover {
      border-color: $colorBlack;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &.disabled {
  }
}

@media screen and (max-width: 1024px) {
  .box {
    input {
      @include typo_16_light;

      &::placeholder {
        @include typo_16_light;
        letter-spacing: 0;
        color: $colorBlack;
      }
    }

    &.sm {
      input {
        &::placeholder {
          @include typo_12_light;
        }
      }
    }
  }
}
