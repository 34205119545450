@import "../../../styles/functions";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.box {
  padding: pxToRem(45px) 0;

  &__inner {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: pxToRem(730px);
    width: 100%;
  }

  .img {
    width: pxToRem(613px);
    height: pxToRem(640px);
    position: absolute;
    left: 0;
    top: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .brands {
    height: pxToRem(144px);
    padding-left: pxToRem(692px);
    padding-bottom: pxToRem(10px);
    display: flex;
    align-items: center;
    gap: 0;

    img {
      max-height: pxToRem(65px);
      max-width: pxToRem(150px);
      width: 100%;
      object-fit: contain;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: pxToRem(60px) pxToRem(90px) pxToRem(90px) pxToRem(500px);
    margin-left: pxToRem(205px);
    max-width: 100%;
    min-height: pxToRem(585px);
    background: var(--content-background-color, $colorMilky);

    &__text {
      display: flex;
      flex-direction: column;
      max-width: pxToRem(753px);
      width: 100%;

      h2 {
        @include typo_50_thin;
        max-width: pxToRem(700px);
        margin-bottom: pxToRem(20px);
      }

      .text {
        @include typo_18_light;
        display: flex;
        flex-direction: column;
        // gap: pxToRem(28px);
      }
    }
    .btn {
      display: flex;
      margin-top: auto;
      gap: pxToRem(20px);
      // margin-right: auto;
      margin-top: pxToRem(83px);
      margin-left: pxToRem(7px);

      > * {
        width: pxToRem(220px);
        min-width: pxToRem(220px);
      }
    }
  }

  :global(.react-tabs__tab-list) {
    border: pxToRem(1px) solid transparent;
    height: pxToRem(144px);
    padding: pxToRem(30px) 0 pxToRem(20px);
    margin: 0;
    padding-left: pxToRem(691px);
    background: transparent;
    border-radius: 0;

    display: flex;
    align-items: flex-start;
  }

  :global(.react-tabs__tab) {
    border: pxToRem(1px) solid transparent;
    opacity: 0.25;
    transition: 0.3s;
    border-radius: 0;
    max-height: 4.0625rem;
    max-width: 9.375rem;
    width: 100%;

    &:not(:last-child) {
      margin-right: pxToRem(25px);
    }

    &:hover {
      opacity: 0.6;
    }
  }

  .tab-panel {
    display: none;
  }

  .animation {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  :global(.react-tabs__tab-panel--selected) {
    display: block;
  }

  :global(.react-tabs__tab--selected) {
    border: pxToRem(1px) solid transparent;
    border-radius: 0;
    background: transparent;
    opacity: 1;

    &:hover {
      opacity: 1;
    }
  }
}

@media (max-width: 1024px) {
  .box {
    padding: pxToRem(20px) 0;

    &__inner {
      padding: 0;
      max-width: 100%;
      min-height: unset;

      .brands {
        height: pxToRem(80px);
        padding-left: pxToRem(20px);
        padding-right: pxToRem(20px);
        padding-bottom: pxToRem(0px);
        display: flex;
        align-items: flex-start;
        gap: 0;

        img {
          max-height: pxToRem(39px);
          max-width: pxToRem(80px);
          width: 100%;
        }
      }

      :global(.react-tabs__tab) {
        padding: 0;

        &:not(:last-child) {
          margin-right: pxToRem(25px);
        }
      }

      .tab-panel {
        .img {
          position: relative;
          width: 100%;
          height: pxToRem(210px);
          background: var(--image-background-color, transparent);
        }

        .content {
          padding: pxToRem(40px) pxToRem(20px);
          margin: 0;
          min-height: unset;

          &__text {
            max-width: 100%;
            width: 100%;

            h2 {
              @include typo_28_thin;
              max-width: 100%;
            }

            .text {
              @include typo_16_light;
            }
          }
        }

        .btn {
          justify-content: center;
          width: 100%;
          margin-top: pxToRem(50px);
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .box {
    .tab-panel {
      .btn {
        display: flex;
        flex-direction: column;
        justify-content: unset;
        width: 100%;
        margin-top: auto;
        gap: pxToRem(15px);
        margin-top: pxToRem(40px);
        margin-left: pxToRem(0px);

        > * {
          width: 100%;
          min-width: 100%;
        }
      }
    }
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
