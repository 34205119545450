@import "../../../styles/functions";
@import "../../../styles/variables.scss";
@import "../../../styles/mixins";

.box {
  width: 100%;
  height: auto;
  background: var(--product-slider-bg, "transparent");
  position: relative;

  .controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: pxToRem(20px);
    // position: absolute;

    width: 100%;

    // padding-top: pxToRem(35px);
  }

  .slider {
    max-width: pxToRem(1920px);

    margin: 0 auto;
    width: 100%;
    height: 100%;
    position: relative;

    &:hover {
      :global(.swiper-button-disabled) {
        opacity: 0.5;
      }
    }
  }

  .slide {
    height: auto;
    display: flex;
    gap: pxToRem(56px);
    align-items: center;

    .image {
      display: flex;
      justify-content: center;
      align-items: center;
      // width: 100%;
      height: 100%;
      width: 50%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .text {
      display: flex;
      flex-direction: column;
      gap: pxToRem(25px);

      width: 100%;
      max-width: 100%;

      .title {
        @include typo_42_thin;
      }

      .description {
        @include typo_28_thin;
      }
    }

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }

  :global(.swiper) {
    width: 100%;
    height: auto;
    border-radius: pxToRem(5px);
    overflow: hidden;
    // padding: pxToRem(22px) pxToRem(90px);

    :global(.swiper-wrapper) {
      height: auto;
      align-items: flex-start;
    }

    :global(.swiper-slide) {
      height: auto;
      width: 100%;
      border-radius: pxToRem(5px);
      overflow: unset !important;
      // margin-right: 0 !important;

      &:not(:last-child) {
      }
    }
  }

  :global(.swiper-button-disabled) {
    background: #fff !important;

    svg {
      path {
        stroke: #f8f1e9 !important;
      }
    }

    &:hover {
      background: #fff !important;
      svg {
        path {
          stroke: #f8f1e9 !important;
        }
      }
    }
  }

  :global(.swiper-button-lock) {
    display: none !important;
  }

  .button_prev,
  .button_next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: pxToRem(40px);
    height: pxToRem(40px);
    border-radius: 50%;
    z-index: 10;
    // border: pxToRem(1px) solid #333333;
    // border: pxToRem(1px) solid #333333;
    background: #fff;
    transition: opacity 0.3s;
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: $colorBlack;
        svg {
          path {
            stroke: $colorWhite;
          }
        }
      }
    }

    svg {
      width: pxToRem(14px);
      path {
        stroke: #aaaaaa;
      }
    }
  }

  .button_prev {
    right: pxToRem(53px);
  }

  .button_next {
    right: 0;
    transform: rotate(-180deg);
  }
}

@media (max-width: 1024px) {
  .box {
    position: relative;

    .slide {
      .image {
        height: 100%;
        width: 100%;
      }
    }

    .button_prev,
    .button_next {
      left: var(--left-position, pxToRem(30px));

      &:active {
        background: $colorBlack;
        svg {
          path {
            stroke: $colorWhite;
          }
        }
      }

      svg {
        width: pxToRem(14px);
      }
    }

    .button_prev {
      right: pxToRem(53px);
    }

    .button_next {
      left: calc(pxToRem(40px) + var(--left-position, pxToRem(30px)) + pxToRem(15px));
      right: 0;
      transform: rotate(-180deg);
    }
    :global(.swiper) {
      :global(.swiper-slide) {
        display: unset;
      }
    }
  }
}

@media (max-width: 767px) {
  .box {
    .controls {
      max-width: 100%;
      padding-top: pxToRem(35px);
      justify-content: center;
    }
    .button_prev,
    .button_next {
      left: var(--left-position, pxToRem(20px));
    }

    .button_next {
      left: calc(pxToRem(40px) + var(--left-position, pxToRem(20px)) + pxToRem(15px));
    }
  }
}
