@import "../../../styles/functions";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.box {
  width: 100%;
  max-width: pxToRem(450px);

  padding: pxToRem(45px) pxToRem(40px) pxToRem(35px);
  border-radius: pxToRem(5px);
  background: $colorMilky;

  .form {
    display: flex;
    flex-direction: column;
    position: relative;

    &__title {
      @include typo_50_light;
      color: $colorBlack;
      text-transform: uppercase;
      margin-bottom: pxToRem(49px);
    }

    &__submit {
      margin-top: pxToRem(45px);
    }

    &__link {
      margin-top: pxToRem(55px);

      * {
        @include typo_14_regular;
        line-height: pxToRem(21px);
      }
    }

    &__alert {
      color: $colorRed;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: pxToRem(10px);
      margin-top: pxToRem(10px);
      width: 100%;
      position: absolute;
      opacity: 0;
      transition: 0.3s;
      @include typo_12_regular;
      line-height: pxToRem(16px);

      &.visible {
        opacity: 1;
      }
    }

    input {
      @include typo_18_light;
    }
  }
}

@media (max-width: 767px) {
  .box {
    padding: pxToRem(30px) pxToRem(20px) pxToRem(15px);

    .form {
      &__title {
        @include typo_34_light;
        margin-bottom: pxToRem(36px);
      }

      &__submit {
        margin-top: pxToRem(66px);
      }

      &__link {
        margin-top: pxToRem(33px);

        * {
          @include typo_14_regular;
          line-height: pxToRem(21px);
        }
      }

      &__alert {
        align-items: flex-start;
        justify-content: flex-start;
        @include typo_10_medium;
        font-weight: 400;
        line-height: pxToRem(12px);
        letter-spacing: 0.02em;
        padding-top: pxToRem(3px);

        svg {
          margin-top: pxToRem(-3px);
        }
      }

      input {
        @include typo_16_light;
      }
    }
  }
}
