@import "../../styles/functions";
@import "../../styles/variables";
@import "../../styles/mixins";

.box {
  .description {
    display: flex;
    flex-direction: column;
    border: pxToRem(1px) solid $colorGray;
    border-radius: pxToRem(5px);
    overflow: hidden;
    color: $colorBlack;

    padding: pxToRem(50px) pxToRem(60px) pxToRem(58px);
    margin-bottom: pxToRem(90px);

    &__title {
      @include typo_24_light;
      margin-bottom: pxToRem(33px);
      color: $colorBlack;
    }

    &__text {
      * {
        @include typo_18_light;
        letter-spacing: 0.01em;
        max-width: pxToRem(650px);
        color: $colorBlack;
      }

      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: pxToRem(76px);
    }
  }
}

@media (max-width: 1024px) {
  .box {
    .description {
      padding: pxToRem(25px) pxToRem(20px);
      margin-bottom: pxToRem(60px);

      &__title {
        @include typo_24_light;
        margin-bottom: pxToRem(30px);
      }

      &__text {
        * {
          @include typo_16_light;
          letter-spacing: 0.01em;
          max-width: 100%;
        }

        grid-template-columns: 1fr;
        gap: pxToRem(0px);
      }
    }
  }
}
