@import "../../styles/functions";
@import "../../styles/variables";
@import "../../styles/mixins";

.box {
  &__inner {
    padding: pxToRem(90px) 0;
  }

  .title {
    @include typo_50_thin;
    margin-bottom: pxToRem(32px);
  }

  .text {
    @include typo_26_light;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
  }

  .button {
    margin-top: pxToRem(32px);
    max-width: pxToRem(199px);
    width: 100%;
  }

  &.opened {
    .title {
      display: none;
    }
  }
}

@media (max-width: 1024px) {
  .box {
    &__inner {
      padding-top: pxToRem(60px);
      padding-bottom: pxToRem(90px);
    }

    .title {
      @include typo_28_thin;
      padding: 0 pxToRem(20px);
      margin-bottom: pxToRem(25px);
    }

    .text {
      padding: 0 pxToRem(20px);
      @include typo_24_light;
    }

    .list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 15px;
    }

    .button {
      padding: 0 pxToRem(20px);
      margin-top: pxToRem(60px);
      width: 100%;
    }

    &.opened {
      .box {
        &__inner {
          padding: 0;
          max-width: 100%;
        }
      }
      .title {
        display: none;
        opacity: 0;
      }

      .button {
        display: none;
      }
    }
  }
}

@media (max-width: 767px) {
  .box {
    &__inner {
    }

    .title {
    }

    .text {
    }

    .list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;

      > * {
        max-width: 100%;
        width: 100%;
      }
    }

    .button {
      display: none;
    }
  }
}

@media (max-width: 460px) {
  .box {
    &__inner {
    }

    .title {
    }

    .text {
    }

    .list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;

      > * {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
