@import "../../../styles/functions";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.box {
  display: flex;
  flex-direction: column;
  gap: pxToRem(12px);
  margin: 0 auto;

  &__inner {
    padding: 0px 0 pxToRem(50px);
  }

  .clear {
    padding-left: pxToRem(20px);
    margin-top: pxToRem(18px);

    &__btn {
      @include typo_14_light;
      color: $colorBlack;
      text-transform: uppercase;
      cursor: pointer;
      padding-bottom: pxToRem(5px);

      border-bottom: pxToRem(1px) solid $colorBlack;

      transition: 0.3s;

      &:hover {
        color: $colorGray;
        border-bottom: pxToRem(1px) solid $colorGray;
      }
    }
  }

  .accordion-regular {
    * {
      font-family: "Roboto Regular";
      font-weight: 400;
    }
  }
}
