@import "../../styles/functions";
@import "../../styles/variables.scss";
@import "../../styles/mixins";

.checkbox {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;

  input {
    position: absolute;
    left: pxToRem(-9999px);
    width: inherit;
    height: inherit;

    & ~ label {
      display: flex;
      align-items: center;
      position: relative;
      display: block;
      min-height: pxToRem(18px);
      padding-left: pxToRem(20px);
      cursor: pointer;
      width: 100%;

      color: $colorGray;

      @include typo_16_regular;
      line-height: pxToRem(21px);

      & a {
        color: $colorBlack;
      }

      &::after {
        content: "";
        display: block;
        position: absolute;
        background: $colorLightGray;
        top: calc(50% - pxToRem(6px));
        left: pxToRem(4px);
        width: pxToRem(12px);
        height: pxToRem(12px);
        max-height: pxToRem(18px);
        opacity: 0;
        transition: opacity 0.2s ease;
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        background: $colorLightGray;
        top: calc(50% - pxToRem(10px));
        left: 0px;
        width: pxToRem(18px);
        height: pxToRem(18px);
        max-height: pxToRem(18px);
        border: pxToRem(1px) solid $colorLightGray;
        border-radius: pxToRem(2px);
        transition: all 0.2s ease;
      }

      &:hover {
        &::before {
        }
      }

      &:not(:empty) {
        margin: 0;
        padding-left: pxToRem(31px);
      }
    }

    &:disabled {
      & ~ label {
        &::after {
          opacity: 0;
        }
      }
    }

    &:checked {
      & ~ label {
        color: $colorBlack;

        &::before {
          border: pxToRem(1px) solid $colorBlack;
          background: $colorBlack;
        }

        &::after {
          opacity: 1;
          background: url(../../../public/svg/check.svg) no-repeat center center;
          background-size: contain;
        }
      }
    }
  }

  &.invalid {
    input {
      & ~ label {
        color: $colorBlack;

        & a {
          color: $colorBlack;
        }

        &::after {
          border-color: $colorBlack;
        }

        &::before {
          border: pxToRem(1px) solid $colorBlack;
        }
      }
    }
  }
}
