@import "../../../styles/functions";
@import "../../../styles/variables.scss";
@import "../../../styles/mixins";

.box {
  max-width: pxToRem(1157px);
  width: 100%;
  height: pxToRem(500px);
  position: relative;

  &.md {
    height: pxToRem(350px);
  }

  .hide {
    position: absolute;
    right: pxToRem(20px);
    top: pxToRem(20px);
    z-index: 10;
    width: pxToRem(30px);
    height: pxToRem(30px);

    &:hover {
      svg {
        circle {
          fill: $colorBlack;
        }
        path {
          stroke: $colorWhite;
        }
      }
    }

    svg {
      * {
        transition: 0.3s;
      }
      width: 100%;
      height: 100%;
    }
  }

  .slider {
    width: 100%;
    height: 100%;
    position: relative;

    &:hover {
      .button_prev,
      .button_next {
        opacity: 1;
      }

      :global(.swiper-button-disabled) {
        opacity: 0.5;
      }
    }
  }

  img {
    width: 100%;
    height: pxToRem(500px);
    object-fit: cover;
  }

  .content {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: pxToRem(20px);
    text-align: center;
    padding-bottom: pxToRem(48px);
    padding-top: pxToRem(48px);
  }

  .title {
    color: $colorWhite;
    @include typo_62_thin;
    max-width: pxToRem(700px);
    width: 100%;
  }

  .description {
    color: $colorWhite;
    max-width: pxToRem(700px);
    width: 100%;
    @include typo_18_light;
  }

  :global(.swiper) {
    width: 100%;
    height: 100%;
    border-radius: pxToRem(5px);
    overflow: hidden;
    :global(.swiper-wrapper) {
      height: 100%;
    }

    :global(.swiper-slide) {
      height: 100%;
      border-radius: pxToRem(5px);
      overflow: hidden;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #00000059;
      }
    }

    :global(.swiper-pagination) {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: pxToRem(15px);
      bottom: pxToRem(31px);
      z-index: 2;
    }

    :global(.swiper-pagination-bullet) {
      width: pxToRem(7px);
      height: pxToRem(7px);
      border: pxToRem(1px) solid $colorWhite;
      background: transparent;
      margin: 0;
      opacity: 1;
    }

    :global(.swiper-pagination-bullet-active) {
      background: $colorLike;
      border: pxToRem(1px) solid $colorLike;
    }
  }

  :global(.swiper-button-disabled) {
    opacity: 0.5;
  }

  .button_prev,
  .button_next {
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: pxToRem(40px);
    height: pxToRem(40px);
    border-radius: 50%;
    // background: $colorWhite;
    background: #33333340;
    z-index: 2;
    top: calc(50% - pxToRem(20px));
    // border: pxToRem(1px) solid #333333;
    transition: opacity 0.3s;
    cursor: pointer;

    svg {
      width: pxToRem(14px);
    }
  }

  .button_prev {
    left: pxToRem(26px);
  }

  .button_next {
    right: pxToRem(26px);
    transform: rotate(-180deg);
  }
}

@media (max-width: 1024px) {
  .box {
    height: pxToRem(370px);

    &.md {
      height: pxToRem(259px);
    }

    .title {
      @include typo_28_thin;
      max-width: 100%;
    }

    .description {
      @include typo_14_light;
      max-width: pxToRem(500px);
    }
  }
}

@media (max-width: 767px) {
  .box {
    height: pxToRem(270px);

    &.md {
      height: pxToRem(270px);
    }

    .button_prev,
    .button_next {
      display: none;
    }

    .content {
      justify-content: flex-start;
      padding-top: pxToRem(40px);
    }

    .title {
      padding: 0 pxToRem(15px);
      line-height: pxToRem(36px);
    }

    .description {
      padding: 0 pxToRem(15px);
      line-height: pxToRem(20px);
    }

    .hide {
      right: pxToRem(10px);
      top: pxToRem(10px);
    }
  }
}
