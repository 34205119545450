@import "../../../styles/functions";
@import "../../../styles/variables.scss";
@import "../../../styles/mixins";

.box {
  background: $colorMilky;
  border-radius: pxToRem(5px);
  overflow: hidden;

  display: flex;
  flex-direction: column;
  max-width: pxToRem(372px);
  width: 100%;
  height: pxToRem(500px);

  height: 100%;

  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 0px 0px pxToRem(25px) 0px #00000026;
  }

  &.horizontal {
    max-width: 100%;
    border-radius: pxToRem(5px);
    border: pxToRem(1px) solid $colorLightGray;
    background: transparent;
    height: pxToRem(65px);

    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;

    > a {
      height: 100%;
    }

    .image {
      border: 0;
      width: pxToRem(100px);
      min-width: pxToRem(100px);
      height: 100%;
      padding: pxToRem(8px) pxToRem(10px);

      .badge {
        position: absolute;
        left: pxToRem(4px);
        top: pxToRem(4px);
        border-radius: 0.875rem;
        padding: 0.1875rem 0.3125rem;
        font-size: 0.4375rem;
        font-weight: 700;
        line-height: 0.375rem;
        width: auto;
      }

      img {
        height: 100%;
      }
    }

    .info {
      flex-direction: row;
      align-items: center;
      height: 100%;
      padding: pxToRem(10px) pxToRem(20px);
      width: 100%;

      border-left: pxToRem(1px) solid $colorLightGray;

      &__row {
        align-items: center;
        &:last-child {
          margin-left: auto;
          margin-top: unset;
        }

        &-title {
          max-width: 100%;
        }

        &:nth-child(1) {
          width: pxToRem(657px);
          min-width: pxToRem(657px);
        }

        &:nth-child(2) {
          width: 35%;
          max-width: pxToRem(350px);
        }
      }

      &__price {
        margin-right: pxToRem(10px);
        width: pxToRem(100px);
        min-width: pxToRem(100px);
        text-align: right;

        &,
        &.discount {
          @include typo_18_regular;
          line-height: pxToRem(18.75px);
        }
      }

      &__number {
        font-size: 0.9rem;
        padding-right: pxToRem(15px);
        width: 100%;
        max-width: pxToRem(145px);
        line-height: pxToRem(18.75px);
        align-self: unset;
        position: relative;

        .badge {
          position: absolute;
          width: pxToRem(30px);
          margin-bottom: pxToRem(5px);
          top: pxToRem(-14px);
        }
      }

      &__discount {
        line-height: pxToRem(20px);
        top: pxToRem(-18px);
        right: 0;

        span {
          @include typo_10_medium;
        }
      }

      &__article {
        @include typo_16_regular;
        line-height: pxToRem(18.75px);
      }

      &__title {
        @include typo_16_regular;
        line-height: pxToRem(18.75px);
        width: 100%;
        margin-top: pxToRem(1px);
        padding-right: pxToRem(15px);

        @include text_overflow_elipses(1);
        white-space: normal;
      }

      &__btns {
        margin-top: 0;
        gap: pxToRem(20px);
      }
    }

    .favorite {
      width: pxToRem(26px);
      height: pxToRem(26px);
      min-width: pxToRem(26px);
      height: pxToRem(26px);

      padding: 0;
    }
  }

  .image {
    background: $colorWhite;
    border: pxToRem(1px) solid $colorMilky;
    border-radius: pxToRem(5px) pxToRem(5px) 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    width: 100%;
    height: pxToRem(300px);

    position: relative;

    display: block;
    width: 100%;
    padding: pxToRem(40px);

    > a {
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    .badge,
    .favorite {
      position: absolute;
      left: pxToRem(24px);
      top: pxToRem(25px);
    }

    .favorite {
      right: pxToRem(22px);
      left: unset;
      top: pxToRem(22px);
    }
  }

  .favorite {
    cursor: pointer;
    width: pxToRem(35px);
    height: pxToRem(30px);

    svg {
      width: 100%;
      height: 100%;
    }

    &:hover {
      svg {
        path {
          stroke: $colorLike;
        }
      }
    }
  }

  .info {
    padding: pxToRem(27px) pxToRem(20px) pxToRem(25px);
    height: 100%;
    display: flex;
    flex-direction: column;

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      &:last-child {
        margin-top: auto;
      }
    }

    &__price {
      @include typo_26_light;
      position: relative;

      &.discount {
        color: $colorRed;
        @include typo_26_light;
        line-height: pxToRem(30px);
      }
    }

    &__discount {
      position: absolute;
      top: pxToRem(-16px);

      display: flex;
      gap: pxToRem(4px);
      align-items: center;

      span {
        @include typo_12_regular;
        text-decoration: line-through;
        color: $colorBlack;
      }
    }

    &__number {
      @include typo_16_regular;
      padding-right: pxToRem(4px);
      align-self: flex-start;
    }

    &__title {
      @include typo_18_light;
      @include text_overflow_elipses(2);
    }

    &__btns {
      display: flex;
      align-items: center;
      gap: pxToRem(15px);
      margin-top: pxToRem(18px);
      width: 100%;

      > button {
        padding-left: pxToRem(10px);
        padding-right: pxToRem(10px);
      }
    }

    &__counter {
      position: relative;

      &:hover {
        .pack-info {
          opacity: 1;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .box {
    max-width: pxToRem(220px);
    height: 100%;

    .image {
      height: pxToRem(140px);
      padding: pxToRem(10px);

      .badge,
      .favorite {
        left: pxToRem(10px);
        top: pxToRem(10px);
      }

      .favorite {
        right: pxToRem(10px);
        left: unset;
        top: pxToRem(10px);
      }
    }

    .favorite {
      cursor: pointer;
      width: pxToRem(20px);
      height: pxToRem(18px);
    }

    .info {
      padding: pxToRem(13px) pxToRem(10px) pxToRem(15px);

      &__row {
        flex-direction: column;
        align-items: baseline;
        gap: pxToRem(4px);
      }

      &__price {
        @include typo_16_light;
        margin-top: pxToRem(6px);
        &.discount {
          @include typo_16_light;
          line-height: pxToRem(21px);
        }
      }

      &__discount {
        position: absolute;
        top: pxToRem(-12px);

        display: flex;
        gap: pxToRem(4px);
        align-items: center;

        span {
          @include typo_10_regular;
        }
      }

      &__number {
        @include typo_12_regular;
        padding-right: pxToRem(4px);
      }

      &__title {
        margin-top: pxToRem(5px);
        margin-bottom: pxToRem(10px);
        @include typo_10_light;
        @include text_overflow_elipses(2);
        letter-spacing: 0.02em;
      }

      &__btns {
        display: flex;
        flex-direction: column;
        gap: pxToRem(16px);
        margin-top: pxToRem(6px);
      }

      &__counter {
        position: relative;
        width: 100%;

        &:hover {
          .pack-info {
            opacity: 1;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .box {
    max-width: pxToRem(135px);

    .image {
      height: pxToRem(110px);
      padding: pxToRem(10px);

      .badge,
      .favorite {
      }
    }

    .info {
      height: 100%;
      &__title {
        margin-bottom: pxToRem(0px);
        max-width: 100%;
      }

      &__row {
        &:nth-child(2) {
          margin-top: auto;
        }
      }

      &__btns {
        gap: pxToRem(26px);

        > button {
          padding-left: pxToRem(5px);
          padding-right: pxToRem(5px);
        }
      }
    }
  }
}
