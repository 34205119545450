@import "../../../styles/functions";
@import "../../../styles/variables.scss";
@import "../../../styles/mixins";

.box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .file {
    display: flex;
    align-items: flex-end;
    gap: pxToRem(10px);

    span {
      @include text_overflow_elipses(1);
      white-space: normal;
    }
  }

  .cancel {
    position: absolute;
    right: 0px;
    top: 0px;
    width: pxToRem(40px);
    height: pxToRem(40px);
  }

  .proggress {
    display: flex;
    flex-direction: column;

    .top {
      display: flex;
      justify-content: space-between;
      gap: pxToRem(20px);
      margin-bottom: pxToRem(6px);

      @include typo_14_light;

      text-transform: uppercase;
    }

    .line {
      height: pxToRem(5px);
      width: 100%;
      border-radius: $borderRadiusBig;
      background: $colorLightGray;
      position: relative;

      &__fill {
        position: absolute;
        left: 0;
        top: 0;
        height: pxToRem(5px);
        border-radius: $borderRadiusBig;
        transition: width 0.3s;
        width: calc(var(--proggress-width, 0) * 1%);
        background: $colorGreen;
      }
    }
  }

  &.error {
    .proggress {
      .line {
        &__fill {
          background: $colorRed;
        }
      }
    }
  }
}
