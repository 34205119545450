@import "../../styles/functions";
@import "../../styles/variables.scss";
@import "../../styles/mixins";

.box {
  position: relative;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  min-width: pxToRem(45px);
  min-height: pxToRem(45px);
  width: pxToRem(45px);
  height: pxToRem(45px);
  background: transparent;
  border-radius: 50%;
  transition: 0.3s;

  .count {
    display: flex;
    align-items: center;
    justify-content: center;

    border: pxToRem(1px) solid $colorMilky;
    background: $colorBlack;
    color: $colorWhite;
    border-radius: 50%;

    width: pxToRem(25px);
    height: pxToRem(25px);

    position: absolute;
    right: pxToRem(-3px);
    top: pxToRem(-3px);
    @include typo_11_medium;
  }

  &:hover {
    background: #fdfbf9;
  }
}

@media (max-width: 1024px) {
  .box {
  }
}
