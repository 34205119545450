@import "../../../styles/functions";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .title {
    padding-top: pxToRem(25px);
    &,
    * {
      @include typo_50_thin;
    }
    max-width: pxToRem(234px);
    display: flex;
    flex-direction: column;
  }

  .table {
    width: 100%;
    max-width: pxToRem(1156px);

    &__header {
      display: flex;
      justify-content: flex-end;
      margin-bottom: pxToRem(8px);
      padding-right: pxToRem(20px);

      span {
        display: flex;
        @include typo_12_medium;
        width: 100%;
        max-width: pxToRem(120px);
        margin-right: pxToRem(20px);

        justify-content: flex-end;
        &:nth-child(1) {
          justify-content: flex-start;
          margin-left: pxToRem(120px);
        }
        &:nth-child(2) {
          justify-content: flex-start;
          margin-right: auto;
          max-width: pxToRem(120px);
        }
        &:nth-child(3) {
          max-width: pxToRem(80px);
          margin-right: pxToRem(50px);
        }
        &:nth-child(4) {
          margin-right: pxToRem(20px);
        }
      }
    }

    &__body {
      > * {
        border-radius: 0;
        border: pxToRem(1px) solid $colorLightGray;
        border-bottom: 0;

        &:first-child {
          border-radius: pxToRem(5px) pxToRem(5px) 0 0;
        }
      }
    }

    &__footer {
      padding: pxToRem(33px) pxToRem(40px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: pxToRem(1px) solid $colorLightGray;
      border-radius: 0 0 pxToRem(5px) pxToRem(5px);

      &-title,
      &-summ {
        @include typo_26_regular;
      }

      &-summ {
        margin-right: pxToRem(0px);
      }
    }
  }
}

@media (max-width: 1024px) {
  .box {
    display: flex;
    flex-direction: column;

    .title {
      &,
      * {
        @include typo_28_thin;
      }
      max-width: 100%;
      display: flex;
      flex-direction: row;
      gap: pxToRem(4px);
      margin-bottom: pxToRem(25px);
      padding: 0;
    }

    .table {
      &__header {
        display: none;
      }

      &__footer {
        padding: pxToRem(25px) pxToRem(20px);

        &-title,
        &-summ {
          @include typo_16_medium;
        }

        &-summ {
          margin-right: pxToRem(0px);
        }
      }
    }
  }
}
