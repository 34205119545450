@import "../../../styles/functions";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.box {
  padding: pxToRem(40px) pxToRem(20px);
  height: 100%;
  background: $colorMilky;

  .search {
    margin-bottom: pxToRem(45px);
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: pxToRem(31px);
    max-height: calc(100% - pxToRem(85px));
    overflow-y: auto;
    overflow-x: hidden;

    &__item {
      display: flex;
      align-items: center;
      gap: pxToRem(15px);
      width: 100%;

      @include typo_21_regular;
    }
  }

  .accordion {
    &__panel {
      height: 0;
      padding: 0;
      border-color: transparent;
      overflow: hidden;
      transition: 0.5s;

      padding-top: pxToRem(32px);

      .list__item {
        padding-left: pxToRem(41px);
        @include typo_18_regular;

        &:not(:last-child) {
          margin-bottom: pxToRem(24px);
        }
      }
    }

    &.open {
      .accordion {
        &__panel {
          height: var(--max-height, auto);
        }
      }
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: pxToRem(20px);
    height: pxToRem(20px);
    transition: 0.3s;
    border-radius: 50%;
    border: pxToRem(1px) solid transparent;
    margin-left: auto;

    &.active {
      transform: rotate(180deg);
      border: pxToRem(1px) solid $colorBlack;
    }

    &:active {
      background: $colorBlack;
      svg {
        path {
          stroke: $colorWhite;
        }
      }
    }
  }
}
