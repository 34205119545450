@import "../../../styles/functions";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.box {
  .sort {
    position: sticky;
    top: pxToRem(80px);
    z-index: 1;
    padding: pxToRem(13px) 0 pxToRem(10px);

    display: flex;
    background: $colorWhite;

    padding-left: pxToRem(35px);

    .button {
      @include typo_12_medium;
      max-width: pxToRem(120px);
      width: 100%;
      text-align: left;
      display: flex;
      gap: pxToRem(10px);
      align-items: center;
      margin-right: pxToRem(30px);

      span {
        border-bottom: 1px solid transparent;
      }

      &:hover {
        span {
          border-bottom: 1px solid $colorBlack;
        }
      }

      svg {
        width: pxToRem(9px);
        height: pxToRem(6px);
      }

      &:nth-child(1) {
        max-width: pxToRem(140px);
        width: 100%;
        margin-right: pxToRem(40px);
      }

      &:nth-child(3) {
        max-width: pxToRem(140px);
        width: 100%;
      }

      &:nth-child(4) {
        max-width: pxToRem(200px);
        width: 100%;
      }

      svg {
        transition: 0.3s;
      }

      &.asc {
        svg {
          transform: rotate(180deg);
        }
      }

      &.desc {
        svg {
          transform: rotate(0);
        }
      }

      &.default {
        svg {
          opacity: 0.2;
        }
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: pxToRem(13px);
  }
}
.empty {
  @include typo_50_thin;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-height: pxToRem(500px);
}

@media (max-width: 1024px) {
  .box {
    .sort {
      display: none;
    }

    .list {
      gap: pxToRem(10px);
    }
  }
}
