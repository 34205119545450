@import "../../styles/functions";
@import "../../styles/variables.scss";
@import "../../styles/mixins";

.box {
  cursor: pointer;
  height: pxToRem(50px);
  border-radius: pxToRem(50px);
  padding: pxToRem(10px) pxToRem(20px);
  width: 100%;
  text-transform: uppercase;
  transition:
    background 0.3s,
    color 0.3s,
    border 0.3s;
  display: inline-block;
  text-align: center;
  position: relative;
  color: $colorBlack;

  @include typo_14_medium;
  letter-spacing: 2%;

  &.md {
    height: pxToRem(40px);
    padding: pxToRem(10px) pxToRem(20px);
  }

  &.sm {
    height: pxToRem(30px);
    padding: pxToRem(5px) pxToRem(15px);
  }

  &.primary {
    background: transparent;
    border: pxToRem(1px) solid $colorBlack;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: $colorBlack;
        color: $colorMilky;
      }
    }

    .spinner {
    }
  }

  &.secondary {
    background: $colorMilky;
    border: pxToRem(1px) solid $colorMilky;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background: $colorBlack;
        color: $colorMilky;
        border: pxToRem(1px) solid $colorBlack;
      }
    }

    .spinner {
    }
  }

  &.disabled {
    border: pxToRem(1px) solid $colorGray;
    pointer-events: none;
    color: $colorGray;
    background: transparent;
  }

  .spinner {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;

    > div {
      width: 100%;
      height: 100%;
      > * {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 1024px) {
  .box {
    &.md {
      height: unset;
      padding: pxToRem(10px) pxToRem(10px);
    }

    &.sm {
      height: unset;
      padding: pxToRem(5px) pxToRem(10px);
    }

    &.sm-2 {
      @include typo_10_regular;
      padding: 0;
      height: unset;
    }

    &.primary {
      &:active {
        background: $colorBlack;
        color: $colorMilky;
      }

      .spinner {
      }
    }

    &.secondary {
      &:active {
        background: $colorBlack;
        color: $colorMilky;
        border: pxToRem(1px) solid $colorBlack;
      }
    }
  }
}
