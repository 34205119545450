@import "../../../../styles/functions";
@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.table {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: pxToRem(1156px);
  padding-bottom: pxToRem(15px);

  border: pxToRem(1px) solid $colorLightGray;
  border-radius: pxToRem(5px);

  .row {
    display: flex;
    width: 100%;
    padding: pxToRem(0px) pxToRem(40px);

    &:not(:last-child) {
      .col {
        border-bottom: pxToRem(1px) solid $colorLightGray;
      }
    }
  }

  .col {
    padding: pxToRem(19px) pxToRem(0px);
    max-width: calc(pxToRem(239px) + pxToRem(40px));
    padding-right: pxToRem(40px);

    width: 100%;

    &.center {
      display: flex;
      align-items: center;
      position: relative;
      .download-file {
        position: absolute;
        right: pxToRem(-12px);
        top: calc(50% - pxToRem(10px));
      }
    }

    &:last-child {
      max-width: calc(pxToRem(239px));
      padding-right: 0;
    }

    &__title {
      @include typo_12_medium;
      margin-bottom: pxToRem(4px);
    }

    &__content {
      @include typo_16_light;
      display: flex;
      align-items: center;
    }

    .big-text {
      @include typo_26_light;
      line-height: pxToRem(30px);
    }

    button:not(.download-file) {
      width: pxToRem(225px);
      padding-left: pxToRem(10px);
      padding-right: pxToRem(10px);
    }

    .download {
      margin: auto auto;
    }

    .address {
      display: flex;
      flex-direction: column;
    }
  }
}

.tooltip {
  max-width: pxToRem(150px);
  margin-left: pxToRem(-10px);
  z-index: 1;
  @include typo_12_regular;

  > div[class*="react-tooltip-arrow"] {
    left: calc(55%) !important;
  }

  * {
    letter-spacing: 0.01em;
  }
}
