@import "../../styles/functions";
@import "../../styles/variables";
@import "../../styles/mixins";

.box {
  width: 100%;
  height: pxToRem(758px);
  position: relative;
  margin-bottom: pxToRem(45px);
  padding-top: pxToRem(157px);

  color: $colorWhite;

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: #00000059;
      z-index: 1;
    }
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    gap: pxToRem(50px);
    padding-left: pxToRem(120px);
  }

  .content {
    width: 100%;
    max-width: pxToRem(843px);
    display: flex;
    flex-direction: column;
    gap: pxToRem(49px);

    .title {
      width: 100%;
      padding: pxToRem(55px) 0 0;

      &,
      * {
        @include typo_62_thin;
        line-height: pxToRem(72px);
      }

      sup {
        font-size: pxToRem(38px);
        line-height: pxToRem(66px);
      }
    }

    .text {
      &,
      * {
        @include typo_18_light;
        line-height: pxToRem(28px);
      }
    }
  }
}

@media (max-width: 1024px) {
  .box {
    height: auto;
    padding: pxToRem(45px) 0;
    margin-bottom: pxToRem(20px);

    &__inner {
      flex-direction: column-reverse;
      padding-left: 0px;
    }

    .form {
      margin-left: pxToRem(40px);
    }

    .content {
      gap: pxToRem(20px);

      .title {
        padding: 0;

        &,
        * {
          @include typo_34_thin;
        }

        sup {
          font-size: pxToRem(24px);
          line-height: pxToRem(38px);
        }
      }

      .text {
        &,
        * {
          @include typo_16_light;
          line-height: pxToRem(21px);
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .box {
    .form {
      margin-left: pxToRem(0px);
    }
  }
}
