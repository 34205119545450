@import "../../../../styles/functions";
@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.box {
  border-radius: pxToRem(5px);
  border: pxToRem(1px) solid $colorLightGray;
  min-height: pxToRem(95px);

  &:not(.visible) {
    .box {
      &__inner {
        > *:not(:first-child) {
          opacity: 0.5;
        }
      }
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
  }

  &__group {
    width: 100%;
    max-width: calc(100% - pxToRem(40px) - pxToRem(61px) - pxToRem(10px));
    padding: pxToRem(10px) pxToRem(10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: pxToRem(5px);
    height: 100%;
    min-height: pxToRem(95px);
    border-left: pxToRem(1px) solid $colorLightGray;

    &-sub {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: pxToRem(10px);
      width: 100%;
      min-width: pxToRem(60px);
      margin-top: auto;

      > div {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 100%;
    min-height: pxToRem(95px);

    img {
      width: pxToRem(60px);
      height: 100%;
      object-fit: contain;
    }
  }

  .title {
    @include typo_10_light;
    font-size: pxToRem(9px);
    line-height: pxToRem(16px);
  }

  .desc {
    display: flex;
    align-items: center;
    gap: pxToRem(4px);

    @include typo_12_medium;
    line-height: pxToRem(18px);
  }

  .name {
    @include typo_14_regular;
    width: 100%;
    max-width: 100%;
    @include text_overflow_elipses(2);

    line-height: pxToRem(16px);
    letter-spacing: 0.02em;
  }

  .pack {
    @include typo_10_light;
  }

  .count,
  .counter {
    @include typo_16_regular;
  }

  .counter {
    border-left: pxToRem(1px) solid $colorLightGray;
    margin-left: pxToRem(10px);
  }

  .number {
    @include typo_16_regular;
    width: 100%;
    max-width: pxToRem(120px);
    margin-right: pxToRem(20px);
  }

  .summ {
    @include typo_18_regular;
    width: 100%;
    max-width: pxToRem(120px);
    margin-right: pxToRem(20px);
  }

  .total {
    @include typo_18_regular;
    width: 100%;
    max-width: pxToRem(100px);
  }

  .delete {
    width: pxToRem(20px);
    height: pxToRem(20px);
    opacity: 1 !important;

    position: absolute;
    right: pxToRem(23px);
    top: calc(50% - pxToRem(9px));

    &:hover {
      svg {
        circle {
          fill: $colorBlack;
        }
        path {
          stroke: $colorWhite;
        }
      }
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &.interactive {
    height: pxToRem(120px);

    .counter {
      margin-right: 0;
    }

    .image {
      position: relative;

      .delete {
        left: pxToRem(5px);
        top: pxToRem(5px);
      }
    }
  }
}
