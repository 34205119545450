@import "../../../styles/functions";
@import "../../../styles/variables.scss";
@import "../../../styles/mixins";

.box {
  width: 100%;
  height: 100%;
  background: $colorWhite;
  border-radius: pxToRem(5px);
  border: pxToRem(1px) solid $colorLightGray;

  padding: pxToRem(35px);

  &.vertical {
    .title {
      width: 100%;
    }

    .photo {
      margin-bottom: pxToRem(10px);
    }

    .contacts {
      margin-top: pxToRem(10px);

      &-info {
        gap: pxToRem(10px);
      }
    }
  }

  .title {
    @include typo_21_regular;
    color: $colorBlack;
    margin-bottom: pxToRem(30px);
    width: 80%;
  }

  .name {
    @include typo_21_light;
    color: $colorBlack;
  }

  .post {
    @include typo_12_regular;
    color: $colorBlack;
  }

  .photo {
    width: pxToRem(110px);
    height: pxToRem(110px);
    min-width: pxToRem(110px);
    min-height: pxToRem(110px);
    border-radius: 50%;
    object-fit: cover;
  }

  .contacts {
    margin-top: pxToRem(17px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: pxToRem(30px);

    &-info {
      padding-top: pxToRem(20px);
      display: flex;
      flex-direction: column;
      gap: pxToRem(7px);
    }

    a {
      @include typo_16_light;
      display: flex;
      gap: pxToRem(10px);
      align-items: center;
      transition: 0.3s;

      span {
        display: flex;
        border-bottom: pxToRem(1px) solid transparent;
      }

      svg {
        width: pxToRem(15px);
        min-width: pxToRem(15px);
      }

      span {
        @include hover_underline;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .box {
    padding: pxToRem(21px) pxToRem(15px);

    .initials {
      display: flex;
      align-items: center;
      gap: pxToRem(15px);
    }

    .title {
      @include typo_18_medium;
      margin-bottom: pxToRem(22px);
      width: 100%;
    }

    .name {
      @include typo_18_regular;
      margin-bottom: pxToRem(5px);
    }

    .post {
      @include typo_12_regular;
    }

    &.vertical {
      .title {
        width: 100%;
      }

      .photo {
        margin-bottom: 0;
      }

      .contacts {
        margin-top: pxToRem(30px);
        gap: pxToRem(15px);

        &-info {
          padding-top: pxToRem(0px);
          gap: pxToRem(7px);
        }

        a {
          @include typo_14_light;
          gap: pxToRem(10px);
        }
      }

      .photo {
        width: pxToRem(65px);
        height: pxToRem(65px);
        min-width: pxToRem(65px);
        min-height: pxToRem(65px);
        margin-bottom: 0;
      }
    }

    .photo {
      width: pxToRem(75px);
      height: pxToRem(75px);
      min-width: pxToRem(75px);
      min-height: pxToRem(75px);
      margin-bottom: 0;
    }
  }
}
