@import "../../styles/functions";
@import "../../styles/variables";
@import "../../styles/mixins";

.box {
  padding: pxToRem(89px) 0 pxToRem(30px);
  background: $colorLightGreen;

  margin-top: auto;

  &__inner {
    display: flex;
    flex-direction: column;
    gap: pxToRem(60px);
  }

  .top {
    display: flex;
    justify-content: flex-start;
    gap: pxToRem(20px);

    .list {
      max-width: pxToRem(370px);
      width: 100%;

      &:first-child {
        max-width: pxToRem(377px);
      }

      &__title {
        @include typo_16_medium;
        text-transform: capitalize;
        margin-bottom: pxToRem(26px);
        color: $colorBlack;
        // position: relative;

        &.bordered {
          .divider {
            display: block;
            height: pxToRem(1px);
            background: $colorBlack;
            width: 100%;
            margin-top: pxToRem(3px);
            margin-bottom: pxToRem(-3px);
          }
        }
      }

      &__item {
        @include typo_16_regular;
        * {
          color: $colorBlack;
        }

        a {
          @include hover_underline;
        }

        &:not(:last-child) {
          margin-bottom: pxToRem(1px);
        }
      }
    }

    .scroll {
      margin-top: pxToRem(34px);
      margin-left: auto;
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .copyright {
      @include typo_16_regular;
      color: $colorBlack;
    }

    .logo {
      width: pxToRem(160px);
    }
  }
}

@media (max-width: 767px) {
  .box {
    padding: pxToRem(40px) 0;

    &__inner {
      gap: pxToRem(60px);
    }

    .top {
      flex-direction: column;
      gap: pxToRem(45px);

      .list {
        max-width: 100%;
        width: 100%;

        &:first-child {
          max-width: 100%;
        }

        &__title {
          margin-bottom: pxToRem(10px);

          &.bordered {
            margin-bottom: pxToRem(20px);
          }
        }
      }

      .scroll {
        display: none;
      }
    }

    .bottom {
      display: flex;
      flex-direction: column-reverse;
      gap: pxToRem(35px);
      position: relative;

      .scroll {
        display: flex;
        position: absolute;
        right: 0;
        top: pxToRem(0px);
      }

      .logo {
        width: pxToRem(110px);
      }
    }
  }
}
