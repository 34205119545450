@import "../../../styles/functions";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.modal {
  padding: pxToRem(40px) 0 0 0;

  button {
    width: pxToRem(220px);
    float: right;
    margin: pxToRem(40px) 0 pxToRem(20px) 0;
  }
  p {
    @include typo_18_light;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    gap: pxToRem(15px);
  }
}

@media screen and (max-width: 767px) {
  .modal {
    padding: pxToRem(20px) 0 0 0;

    .inputs {
      max-height: pxToRem(380px);
      overflow-y: auto;
      margin-bottom: pxToRem(30px);
    }

    p {
      padding-top: pxToRem(10px);
      @include typo_16_light;
    }

    button {
      width: 100%;
      margin-top: auto;
    }
  }
}
