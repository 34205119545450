@import "../../../styles/functions";
@import "../../../styles/variables";
@import "../../../styles/mixins";

.box {
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: pxToRem(12px);

  &.grid {
    display: grid;
    grid-template-columns: repeat(var(--grid-template-cols, 3), 1fr);
    gap: pxToRem(19px) pxToRem(20px);
  }

  &__item {
    &.full {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
}

@media (max-width: 1024px) {
  .box {
    &.grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: pxToRem(20px) pxToRem(20px);
    }

    &__item {
      width: calc((100% - pxToRem(40px)) / 3);

      > * {
        max-width: 100%;
      }

      &.full {
        width: 100%;
      }
      &.partial {
        // grid-column-end: 3;
      }
    }
  }
}

@media (max-width: 767px) {
  .box {
    &.grid {
      gap: pxToRem(10px) pxToRem(10px);
    }

    &__item {
      width: calc((100% - pxToRem(10px)) / 2);
    }
  }
}
